'use client'

import React, { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
	categoriesToJSON,
	Inventory, Listing,
	statusesToJSON,
	stockLevelToJSON
} from "../utils/types";
import {QuantitySizing} from "app-ts-types/generic";
import ListingImage from "./Image";
import {statuses} from "../utils/data";
import ListingImageCloud from "./ListingImageCloud";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

export default function InventoryItem({inventory, onClose, editClick, deleteClick, del, handleCreateListing, showListingDisplay}:{inventory: Inventory; del?:boolean; onClose: () => void; editClick: (inventory:Inventory) => void; deleteClick: (inventory:Inventory) => void; handleCreateListing: (inventory:Inventory) => void; showListingDisplay?: (listing:Listing) => void;}) {
	const [open, setOpen] = useState(true)
	const [deleting, setDeleting] = useState<boolean>(del || false);

	return (
		<Dialog open={open} onClose={onClose} className="relative z-[100]">
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
			/>

			<div className="fixed inset-0 overflow-hidden">
				<div className="absolute inset-0 overflow-hidden">
					<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
						<DialogPanel
							transition
							className="pointer-events-auto relative w-96 transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
						>
							<TransitionChild>
								<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
									<button
										type="button"
										onClick={() => {
											setOpen(false);
											onClose();
										}}
										className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
									>
										<span className="absolute -inset-2.5" />
										<span className="sr-only">Close panel</span>
										<XMarkIcon aria-hidden="true" className="h-6 w-6" />
									</button>
								</div>
							</TransitionChild>
							<div className="h-full overflow-y-auto bg-white dark:bg-gray-900 p-8">
								<div className="space-y-6 pb-16">
									<div>
										<div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
											<ListingImage
												imageUrl={inventory.imageUrl}
												title={inventory.name+"\n("+inventory.SKU+")"}
												className="object-cover"
											/>
										</div>
										<div className="mt-4 flex items-start justify-between">
											<div>
												<h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
													<span className="sr-only">Details for </span>{inventory.name}
												</h2>
												<p className="text-sm font-medium text-gray-500">${inventory.purchaseDetails!.price}</p>
											</div>
										</div>
									</div>
									<div>
										<h3 className="font-medium text-gray-900 dark:text-gray-100">Information</h3>
										<dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">SKU</dt>
												<dd className="text-gray-900 dark:text-gray-100">{inventory.SKU}</dd>
											</div>
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">Quantity</dt>
												<dd className="text-gray-900 dark:text-gray-100">{inventory.totalQuantity}</dd>
											</div>
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">Description</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{inventory.description || "No Description"}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Colorway</dt>
												<dd className="text-gray-900 dark:text-gray-100">{inventory.color}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Brand</dt>
												<dd className="text-gray-900 dark:text-gray-100">{inventory.brand}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Purchase Place</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{inventory.purchaseDetails?.purchasePlace || "N/A"}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Purchase Date</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{inventory.purchaseDetails?.purchaseDate || "N/A"}</dd>
											</div>
											<div className="flex justify-between space-x-4 py-3 text-sm font-medium">
												<dt className="text-gray-500">Collection</dt>
												<dd className="text-gray-900 text-right text-sm dark:text-gray-100">{inventory.collectionId || "N/A"}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Category</dt>
												<dd className="text-gray-900 dark:text-gray-100">{categoriesToJSON(inventory.category)}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Created</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{new Date(inventory.createdAt).toDateString()}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Last modified</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{new Date(inventory.updatedAt).toDateString()}</dd>
											</div>
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">Sizes</dt>
												<dd className="text-gray-900 dark:text-gray-100"> {inventory.sizes.map((size: QuantitySizing) =>
													<button
														type="button"
														className="ml-2 inline-flex p-2 items-center justify-center rounded bg-lavender text-deepPurple hover:bg-brightPurple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
													>
														<span className="sr-only">{size.size}</span>
														<span className={"text-xs"}>{size.size}</span>
													</button>)} </dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Status</dt>
												<dd className="text-gray-900 dark:text-gray-100">
													<div
														className={classNames(statuses[statusesToJSON(inventory.status)], 'flex-none rounded cursor-pointer px-2 py-1')}>
														{statusesToJSON(inventory.status)}
													</div>
												</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Stock Level</dt>
												<dd className="text-gray-900 dark:text-gray-100">
													<div
														className={classNames(statuses[stockLevelToJSON(inventory.stockLevel)], 'flex-none rounded cursor-pointer px-2 py-1')}>
														{stockLevelToJSON(inventory.stockLevel)}
													</div>
												</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Listed On</dt>
												<dd className="text-gray-900 dark:text-gray-100">
													<ListingImageCloud showListingDisplay={showListingDisplay} inventoryId={inventory.id} marketplaces={inventory.platform}/>
												</dd>
											</div>
										</dl>
									</div>
									<div className="flex space-x-4">
										<button
											type="button"
											onClick={() => {
												setOpen(false);
												editClick(inventory);
											}}
											className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
										>
											Edit
										</button>
										<button
											type="button"
											disabled={deleting}
											onClick={async () => {
												setDeleting(true);
												await deleteClick(inventory);
												setOpen(false)
											}}
											className="flex-1 rounded-md text-white !text-center bg-red-600 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500"
										>
											{deleting ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												'Delete'
											)}
										</button>
										<button
											type="button"
											onClick={() => {
												setOpen(false);
												handleCreateListing(inventory);
											}}
											className="flex-1 rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
										>
											List
										</button>
									</div>
								</div>
							</div>
						</DialogPanel>
					</div>
				</div>
			</div>
		</Dialog>
	)
}
