import axiosInstance from './server';
import { Inventory, CreateInventoryRequest, UpdateInventoryRequest, GetInventoryResponse, GetInventoriesResponse } from 'app-ts-types/inventories';

// Get an inventory item by ID
export const getInventoryById = async (id: string): Promise<GetInventoryResponse> => {
	const response = await axiosInstance.get<GetInventoryResponse>(`/inventory/${id}`);
	return response.data;
};

// Get an inventories
export const getInventories = async (): Promise<GetInventoriesResponse> => {
	const response = await axiosInstance.get<GetInventoriesResponse>(`/inventory`);
	return response.data;
};

// Create a new inventory item
export const createInventory = async (data: Inventory): Promise<GetInventoryResponse> => {
	const response = await axiosInstance.post<GetInventoryResponse>('/inventory', data);
	return response.data;
};

// Update an inventory item by ID
export const updateInventory = async (id: string, data: Inventory): Promise<GetInventoryResponse> => {
	const response = await axiosInstance.put<GetInventoryResponse>(`/inventory/${id}`, data);
	return response.data;
};

// Delete an inventory item by ID
export const deleteInventory = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/inventory/${id}`);
};
