import axios from 'axios';
const API_BASE_URL = 'https://api.tracknlist.com/api';
export const WWW_BASE_URL = 'https://api.tracknlist.com/api';
// const API_BASE_URL = 'http://localhost:5000/api';
// export const WWW_BASE_URL = 'http://localhost:5000/api';
const axiosInstance = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});
export const setAuthToken = (token: string) => {
	if (token) {
		axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete axiosInstance.defaults.headers.common['Authorization'];
	}
};
// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				// Attempt to refresh the token here
				const refreshToken = localStorage.getItem('refresh_token'); // Replace 'refreshToken' with your refresh token key
				const response = await axios.post(API_BASE_URL+'/token/refresh', { token: refreshToken });
				console.log(response.data);
				const newToken = response.data.token;
				localStorage.setItem('token', newToken);
				setAuthToken(newToken);
				// Retry the original request with the new token
				originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
				return axiosInstance(originalRequest);
			} catch (refreshError) {
				// Handle token refresh failure (e.g., redirect to login)
				console.error('Token refresh failed:', refreshError);
				localStorage.removeItem('token');
				localStorage.removeItem('refresh_token');
				window.location.href = '/login'; // Redirect to login page
			}
		}

		return Promise.reject(error);
	}
);
axiosInstance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token'); // Replace 'token' with your token key
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
export default axiosInstance;
