/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
'use client'

import React, {useState} from 'react'
import {
	Dialog,
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Switch,
	Tab,
	TabGroup,
	TabList,
	TabPanel,
	TabPanels,
	Transition
} from '@headlessui/react'
import {ChevronDownIcon, StarIcon} from '@heroicons/react/20/solid'
import {ChevronRightIcon, HeartIcon, MinusIcon, PlusIcon, TrashIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Categories, categoriesToJSON, Listing} from "../utils/types";
import {PencilIcon} from "@heroicons/react/16/solid";
import {marketplaceImages} from "./ListingImageCloud";
import ImageUploader from "./ImgUploader";
import {Shipping} from "app-ts-types/listings";
import {generateUUID, productSizes} from "../utils/data";
import {PlusCircleIcon, XCircleIcon, MinusCircleIcon} from "@heroicons/react/24/solid";
import {calculateTotalQuantity} from "./CreateInventory";
import {userStore} from "../state/User";
import {createListing, updateListing} from "../api/listings";
import {listingsStore} from "../state/Listing";
import {NotificationPopupComponent} from "./NotificationPopup";

const product = {
	name: 'Zip Tote Basket',
	price: '$140',
	rating: 4,
	images: [
		{
			id: 1,
			name: 'Angled view',
			src: 'https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg',
			alt: 'Angled front view with bag zipped and handles upright.',
		},
		// More images...
	],
	colors: [
		{name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700'},
		{name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400'},
		{name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500'},
	],
	description: `
    <p>The Zip Tote Basket is the perfect midpoint between shopping tote and comfy backpack. With convertible straps, you can hand carry, should sling, or backpack this convenient and spacious bag. The zip top and durable canvas construction keeps your goods protected for all-day use.</p>
  `,
	details: [
		{
			name: 'Features',
			items: [
				'Multiple strap configurations',
				'Spacious interior with top zip',
				'Leather handle and tabs',
				'Interior dividers',
				'Stainless strap loops',
				'Double stitched construction',
				'Water-resistant',
			],
		},
		// More sections...
	],
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

export default function ListingItem({listing, onClose, editClick, deleteClick, del, edit}: {
	listing: Listing;
	del?: boolean;
	edit?: boolean;
	onClose: () => void;
	editClick: (listing: Listing) => void;
	deleteClick: (listing: Listing) => void;
}) {
	const [showDialog, setShowDialog] = useState(true);
	const [deleting, setDeleting] = useState<boolean>(del || false);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [editMode, setEditMode] = useState(edit || false);
	const [updatedListing, setUpdatedListing] = useState<Listing>(listing);
	const [loading, setLoading] = useState(false);
	const [currentSizeIndex, setCurrentSizeIndex] = useState(0);
	const [sizeType, setSizeType] = useState<'US' | 'EU'>('US');
	const [openCategory, setOpenCategory] = useState<string | null>(null);
	const [openMenu, setOpenMenu] = useState(false);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const {name, value} = e.target;
		setUpdatedListing({...updatedListing, [name]: value});
	};

	const handleToggleSizeType = () => {
		setSizeType((prevType) => (prevType === 'US' ? 'EU' : 'US'));
	};

	const handleSizeChange = (index: number, field: string, value: string | number) => {
		const newSizes = [...updatedListing.sizes];
		newSizes[index] = {...newSizes[index], [field]: value};
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
		setOpenCategory(null);
		setOpenMenu(false);
	};

	const handleAddSize = () => {
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: [...prevInventory.sizes, {size: '', quantity: 0}],
		}));
	};

	const handleCategoryClick = (category: string) => {
		setOpenCategory(openCategory === category ? null : category);
	};

	const handleRemoveSize = (index: number) => {
		const newSizes = updatedListing.sizes.filter((_: any, i: any) => i !== index);
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
	};

	const handleRemoveImage = (index: number) => {
		setUpdatedListing((prevListing: Listing) => {
			const newFiles = [...prevListing.imageUrls];
			newFiles.splice(index, 1);
			return {
				...prevListing,
				imageUrls: newFiles,
			}
		});
	};

	// Handle input changes for shipping fields
	const handleShippingChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const { name, value } = e.target;
		setUpdatedListing({
			...updatedListing,
			shipping: {
				...updatedListing.shipping,
				[name]: value,
			} as Shipping,
		});
	};

	const updateListingFunction = async () => {
		updatedListing.user = userStore.getUser()?.id as string;
		updatedListing.updatedAt = new Date().getTime();
		await updateListing(updatedListing.id, {...updatedListing })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					listingsStore.updateListing(res.data as Listing);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: `Listing Updated`,
						message: `Listing information successfully updated`
					});
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating listing object",
						message: res.error?.errorMessage || res.message
					});
					console.log(res)
				}
			})
	}

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			await updateListingFunction();
			setEditMode(false);
		} catch (error:any) {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Error updating listing object",
				message: error.message
			});
			console.error('Error updating listing:', error);
		} finally {
			setLoading(false);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />;
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white dark:text-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<div className="mx-auto">
									<div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
										<div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
											{/* Image gallery */}
											<TabGroup as="div" className="flex flex-col-reverse">
												{/* Image selector */}
												<div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
													<TabList className="grid grid-cols-4 gap-6">
														{updatedListing.imageUrls.map((image, index) => (
															<Tab key={image}
															     className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white dark:bg-gray-800 text-sm font-medium uppercase text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none">
																<span className="sr-only">{updatedListing.title}</span>
																<span className="absolute inset-0 overflow-hidden rounded-md">
								                  <img alt="" src={image} className="h-full w-full object-cover object-center"/>
								                </span>
																<span
																	aria-hidden="true"
																	className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
																/>
																{
																	editMode && (
																		<button
																			onClick={() => { handleRemoveImage(index) }}
																			className="absolute z-50 top-2 left-2 bg-red-500 text-white p-1 rounded-full duration-300"
																		>
																			<XMarkIcon className={"h-6 w-6"}/>
																		</button>
																	)
																}
															</Tab>
														))}
														{editMode && (
															<div
																className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-gray-100 dark:bg-gray-700 text-sm font-medium uppercase text-gray-500 hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none">
																<ImageUploader
																	onUpload={(files) => {
																		setUpdatedListing({
																			...updatedListing,
																			imageUrls: [...updatedListing.imageUrls, ...files],
																		});
																	}}
																	files={[]}
																	maxFiles={25}
																	multiple={true}
																	miniUpload={true}
																/>
															</div>
														)}
													</TabList>
												</div>

												<TabPanels className="aspect-h-1 aspect-w-1 w-full">
													{updatedListing.imageUrls.map((image) => (
														<TabPanel key={image}>
															<img
																alt={updatedListing.title}
																src={image}
																className="h-full w-full object-cover object-center sm:rounded-lg"
															/>
														</TabPanel>
													))}
												</TabPanels>
											</TabGroup>

											{/* Product info */}
											<div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
												{/* Display the listing platform */}
												<div className="flex items-center gap-2">
													<img
														src={marketplaceImages[updatedListing.platform]?.src}
														alt={marketplaceImages[updatedListing.platform]?.alt}
														className="h-6 w-6"
													/>
													<span className="text-gray-800 dark:text-gray-200">
							            {marketplaceImages[updatedListing.platform]?.alt}
							          </span>
												</div>

												{!editMode ? (
													<>
														<h1 className="text-3xl font-bold tracking-tight dark:text-gray-200 text-gray-900 mt-4">
															{updatedListing.title}
														</h1>

														<div className="mt-3">
															<p className="text-3xl tracking-tight text-gray-900 dark:text-gray-200">
																{updatedListing.currencyCode} {updatedListing.price}
															</p>
														</div>

														{/* Important details */}
														<div className="mt-6 space-y-2">
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Brand:</strong> {updatedListing.brand}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>SKU:</strong> {updatedListing.SKU}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Seller:</strong> {updatedListing.seller}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Location:</strong> {updatedListing.location}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Quantity:</strong> {updatedListing.quantity}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Color:</strong> {updatedListing.color}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Condition:</strong> {updatedListing.condition}
															</p>
														</div>

														{/* Description */}
														<div className="mt-6">
															<h3 className="text-lg font-medium text-black dark:text-white">Description</h3>
															<div className="mt-2 space-y-6 text-base text-gray-700 dark:text-gray-400">
																{updatedListing.description}
															</div>
														</div>

														{/* Shipping and Tags as collapsible sections */}
														<section aria-labelledby="details-heading" className="mt-12">
															<h2 id="details-heading" className="sr-only">
																Additional details
															</h2>

															<div
																className="divide-y divide-gray-200 border-t border-b border-gray-200 dark:border-gray-700">
																{/* Shipping Information */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Shipping Information
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul>
																					<li>
																						<strong className={"text-black dark:text-white"}>Cost:</strong> {updatedListing.shipping?.cost}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Estimated Delivery Time:</strong>{' '}
																						{updatedListing.shipping?.estimatedDeliveryTime} days
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Method:</strong> {updatedListing.shipping?.method}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Package Weight:</strong> {updatedListing.shipping?.packageWeight}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Package Dimensions:</strong>{' '}
																						{updatedListing.shipping?.packageDimensionWidth} x{' '}
																						{updatedListing.shipping?.packageDimensionHeight}
																					</li>
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Sizes */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Sizes
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (<MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>)}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul className="flex flex-wrap gap-2">
																					{updatedListing.sizes.map((size, index) => (
																						<li key={index}
																						    className="bg-lavender text-deepPurple px-2 py-1 rounded">
																							{size.size || "N/A"}
																						</li>
																					))}
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Tags and Keywords */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Tags and Keywords
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul className="flex flex-wrap gap-2">
																					{updatedListing.tagsAndKeywords.map((tag) => (
																						<li key={tag} className="bg-gray-200 dark:bg-gray-600 px-2 py-1 rounded">
																							{tag}
																						</li>
																					))}
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>
															</div>
														</section>

														{/* Action Buttons */}
														<div className="mt-6 flex space-x-4">
															<a
																href={updatedListing.listingUrl}
																target="_blank"
																rel="noopener noreferrer"
																className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700"
															>
																Show Listing
															</a>
															<button
																onClick={() => setEditMode(true)}
																className="flex items-center justify-center rounded-md border border-transparent bg-gray-400 dark:bg-gray-700 px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 dark:hover:bg-gray-600"
															>
																<PencilIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																Edit
															</button>
															<button
																disabled={deleting}
																onClick={async () => {
																	setDeleting(true);
																	await deleteClick(listing);
																	setShowDialog(false);
																}}
																className="flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700"
															>
																{deleting ? (
																	<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
																	     fill="none"
																	     viewBox="0 0 24 24">
																		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
																		        strokeWidth="4"></circle>
																		<path className="opacity-75" fill="currentColor"
																		      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
																	</svg>
																) : (
																	<>
																		<TrashIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																		Delete
																	</>
																)}
															</button>
															<button
																onClick={() => {console.log("listing");}}
																className="flex items-center justify-center rounded-md border border-transparent bg-red-800 px-4 py-2 text-sm font-medium text-white hover:bg-red-950"
															>
																<MinusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																DeList
															</button>
														</div>
													</>
												) : (
													/* Edit Mode Form */
													<form
														onSubmit={(e) => {
															e.preventDefault();
															handleFormSubmit();
														}}
													>
														<div className="space-y-4">
															<div>
																<label
																	htmlFor="title"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Title <span className="text-red-500">*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="title"
																		name="title"
																		type="text"
																		value={updatedListing.title}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm focus:ring-2 focus:ring-indigo-500"
																	/>
																</div>
															</div>

															{/* Description */}
															<div>
																<label
																	htmlFor="description"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Description <span className="text-red-500">*</span>
																</label>
																<div className="mt-2">
                <textarea
	                id="description"
	                name="description"
	                value={updatedListing.description}
	                onChange={handleInputChange}
	                className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm focus:ring-2 focus:ring-indigo-500"
	                rows={4}
	                required
                />
																</div>
															</div>

															{/* SKU */}
															<div>
																<label
																	htmlFor="SKU"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	SKU <span className="text-red-500">*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="SKU"
																		name="SKU"
																		type="text"
																		value={updatedListing.SKU}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm focus:ring-2 focus:ring-indigo-500"
																		required
																	/>
																</div>
															</div>

															{/* Brand */}
															<div>
																<label
																	htmlFor="brand"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Brand <span className="text-red-500">*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="brand"
																		name="brand"
																		type="text"
																		value={updatedListing.brand}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm focus:ring-2 focus:ring-indigo-500"
																		required
																	/>
																</div>
															</div>

															{/* Price */}
															<div>
																<label
																	htmlFor="price"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Price <span className="text-red-500">*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="price"
																		name="price"
																		type="number"
																		value={updatedListing.price}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm focus:ring-2 focus:ring-indigo-500"
																		required
																	/>
																</div>
															</div>

															{/* Seller */}
															<div>
																<label
																	htmlFor="seller"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Seller <span className="text-red-500">*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="seller"
																		name="seller"
																		type="text"
																		value={updatedListing.seller}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																		required
																	/>
																</div>
															</div>

															{/* Location */}
															<div>
																<label
																	htmlFor="location"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Location <span className="text-red-500">*</span>
																</label>
																<div className="mt-2">
																	<input
																		id="location"
																		name="location"
																		type="text"
																		value={updatedListing.location}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																		required
																	/>
																</div>
															</div>

															{/* Category */}
															<div>
																<label
																	htmlFor="category"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Category <span className="text-red-500">*</span>
																</label>
																<div className="mt-2">
																	<select
																		id="category"
																		name="category"
																		value={updatedListing.category}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																	>
																		<option value="">Select a category</option>
																		{Object.values(Categories)
																			.filter(value => typeof value === 'number') // Ensure we're only dealing with numeric values
																			.map((value) => (
																				<option key={value} value={categoriesToJSON(value as Categories)}>
																					{categoriesToJSON(value as Categories)}
																				</option>
																			))}
																	</select>
																</div>
															</div>

															{/* Currency Code */}
															<div>
																<label
																	htmlFor="currencyCode"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Currency Code
																</label>
																<div className="mt-2">
																	<select
																		id="currencyCode"
																		name="currencyCode"
																		value={updatedListing.currencyCode}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																	>
																		<option value="USD">USD ($)</option>
																		<option value="EUR">EUR (€)</option>
																		<option value="GBP">GBP (£)</option>
																		{/* Add more currency options as needed */}
																	</select>
																</div>
															</div>

															{/* Condition */}
															<div>
																<label
																	htmlFor="condition"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Condition
																</label>
																<div className="mt-2">
																	<select
																		id="condition"
																		name="condition"
																		value={updatedListing.condition}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																	>
																		<option value="new">New</option>
																		<option value="slightly used">Slightly Used</option>
																		<option value="used">Used</option>
																	</select>
																</div>
															</div>

															{/* Color */}
															<div>
																<label
																	htmlFor="color"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Color
																</label>
																<div className="mt-2">
																	<input
																		id="color"
																		name="color"
																		type="text"
																		value={updatedListing.color}
																		onChange={(e) => {
																			handleInputChange(e);
																		}}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																	/>
																</div>
															</div>

															{/* Tags and Keywords */}
															<div>
																<label
																	htmlFor="tagsAndKeywords"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Tags and Keywords
																</label>
																<div className="mt-2">
																	<input
																		id="tagsAndKeywords"
																		name="tagsAndKeywords"
																		type="text"
																		value={updatedListing.tagsAndKeywords.join(', ')}
																		onChange={(e) => {
																			setUpdatedListing({
																				...updatedListing,
																				tagsAndKeywords: e.target.value.split(',').map((tag) => tag.trim()),
																			});
																		}}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																	/>
																</div>
																<p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
																	Separate tags with commas.
																</p>
															</div>

															{/* Quantity */}
															<div>
																<label
																	htmlFor="quantity"
																	className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																>
																	Quantity
																</label>
																<div className="mt-2">
																	<input
																		id="quantity"
																		name="quantity"
																		type="number"
																		value={updatedListing.quantity}
																		onChange={handleInputChange}
																		className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm focus:ring-2 focus:ring-indigo-500"
																	/>
																</div>
															</div>

															<div className="mt-3 relative">
																<div className="flex w-24 items-center relative justify-between">
																	<label htmlFor="sizes"
																	       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
																		Sizes
																	</label>
																	<Switch
																		checked={sizeType === 'EU'}
																		onChange={handleToggleSizeType}
																		className={`${sizeType === 'EU' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
																	>
																		<span className="sr-only">Toggle Size Type</span>
																		<span
																			className={`${sizeType === 'EU' ? 'translate-x-6' : 'translate-x-1'} inline-block text-black text-[0.5rem] font-avenir-bold h-4 w-4 pt-[0.15rem] transform rounded-full bg-white transition`}>
														{sizeType}
														</span>
																	</Switch>
																</div>
																{
																	openMenu && <Transition
																		as={"div"}
																		enter="transition ease-out duration-100"
																		enterFrom="transform opacity-0 scale-95"
																		enterTo="transform opacity-100 scale-100"
																		leave="transition ease-in duration-75"
																		leaveFrom="transform opacity-100 scale-100"
																		leaveTo="transform opacity-0 scale-95"
																	>
																		<div
																			className="absolute left-0 bottom-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																			<div className="py-1 max-h-60 overflow-y-auto">
																				{Object.keys(productSizes).map((category) => (
																					<div key={category}>
																						<div>
																							<div
																								onClick={(e: any) => {
																									e.preventDefault();
																									handleCategoryClick(category)
																								}}
																								className={`inline-flex w-full px-4 py-2 text-left cursor-pointer text-sm font-semibold text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																							>
																								{category.charAt(0).toUpperCase() + category.slice(1)}
																								<ChevronRightIcon aria-hidden="true"
																								                  className="-mr-1 h-5 w-5 text-gray-400"/>
																							</div>
																						</div>
																					</div>
																				))}
																			</div>
																		</div>
																	</Transition>
																}
																{
																	openCategory &&
																	<Transition
																		as={"div"}
																		enter="transition ease-out duration-100"
																		enterFrom="transform opacity-0 scale-95"
																		enterTo="transform opacity-100 scale-100"
																		leave="transition ease-in duration-75"
																		leaveFrom="transform opacity-100 scale-100"
																		leaveTo="transform opacity-0 scale-95"
																	>
																		<div
																			className="absolute left-[225px] -bottom-2.5 z-20 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
																			<div className="py-1">
																				{productSizes[openCategory][sizeType.toLowerCase()].map((sizeOption) => (
																					<div key={sizeOption}>
																						<button
																							type="button"
																							onClick={(e) => {
																								e.preventDefault();
																								handleSizeChange(currentSizeIndex, 'size', sizeOption)
																							}}
																							className={`block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																						>
																							{sizeOption}
																						</button>
																					</div>
																				))}
																			</div>
																		</div>
																	</Transition>
																}
																{updatedListing.sizes.map((size, index) => (
																	<div key={index} className="flex items-center mt-1">
																		<div className="relative inline-block text-left w-1/2 mr-2">
																			<div>
																				<div
																					onClick={() => {
																						setCurrentSizeIndex(index)
																						setOpenMenu(!openMenu)
																					}}
																					className="inline-flex w-full cursor-pointer justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
																					{size.size || 'Select Size'}
																					<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
																				</div>
																			</div>
																		</div>
																		<input
																			type="number"
																			name={`quantity-${index}`}
																			value={size.quantity}
																			onChange={(e) => {
																				e.preventDefault();
																				handleSizeChange(index, 'quantity', parseInt(e.target.value))
																			}}
																			className="mr-2 block w-1/2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
																			placeholder="Quantity"
																		/>
																		<button
																			type="button"
																			onClick={() => handleRemoveSize(index)}
																			className={`${index === 0 && "invisible"} text-red-500 hover:text-red-700`}
																		>
																			<XCircleIcon className="h-6 w-6"/>
																		</button>
																		<button
																			type="button"
																			onClick={handleAddSize}
																			className="text-blue-500 hover:text-blue-700 ml-2"
																		>
																			<PlusCircleIcon className="h-6 w-6"/>
																		</button>
																	</div>
																))}
															</div>

															{/* Shipping Information */}
															<div>
																<h2 className="text-lg font-medium text-gray-900 dark:text-white mt-4">
																	Shipping Information
																</h2>
																<div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
																	{/* Shipping Cost */}
																	<div>
																		<label
																			htmlFor="cost"
																			className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																		>
																			Shipping Cost
																		</label>
																		<div className="mt-2">
																			<input
																				id="cost"
																				name="cost"
																				type="number"
																				value={updatedListing.shipping?.cost || ''}
																				onChange={handleShippingChange}
																				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																			/>
																		</div>
																	</div>

																	{/* Estimated Delivery Time */}
																	<div>
																		<label
																			htmlFor="estimatedDeliveryTime"
																			className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																		>
																			Estimated Delivery Time (days)
																		</label>
																		<div className="mt-2">
																			<input
																				id="estimatedDeliveryTime"
																				name="estimatedDeliveryTime"
																				type="number"
																				value={updatedListing.shipping?.estimatedDeliveryTime || ''}
																				onChange={handleShippingChange}
																				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																			/>
																		</div>
																	</div>

																	{/* Shipping Method */}
																	<div>
																		<label
																			htmlFor="method"
																			className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																		>
																			Shipping Method
																		</label>
																		<div className="mt-2">
																			<input
																				id="method"
																				name="method"
																				type="text"
																				value={updatedListing.shipping?.method || ''}
																				onChange={handleShippingChange}
																				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																			/>
																		</div>
																	</div>

																	{/* Package Weight */}
																	<div>
																		<label
																			htmlFor="packageWeight"
																			className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																		>
																			Package Weight
																		</label>
																		<div className="mt-2">
																			<input
																				id="packageWeight"
																				name="packageWeight"
																				type="number"
																				value={updatedListing.shipping?.packageWeight || ''}
																				onChange={handleShippingChange}
																				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																			/>
																		</div>
																	</div>

																	{/* Package Dimensions */}
																	<div>
																		<label
																			htmlFor="packageDimensionWidth"
																			className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																		>
																			Package Width
																		</label>
																		<div className="mt-2">
																			<input
																				id="packageDimensionWidth"
																				name="packageDimensionWidth"
																				type="number"
																				value={updatedListing.shipping?.packageDimensionWidth || ''}
																				onChange={handleShippingChange}
																				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																			/>
																		</div>
																	</div>

																	<div>
																		<label
																			htmlFor="packageDimensionHeight"
																			className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
																		>
																			Package Height
																		</label>
																		<div className="mt-2">
																			<input
																				id="packageDimensionHeight"
																				name="packageDimensionHeight"
																				type="number"
																				value={updatedListing.shipping?.packageDimensionHeight || ''}
																				onChange={handleShippingChange}
																				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-gray-700 py-1.5 px-2 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500"
																			/>
																		</div>
																	</div>
																</div>
															</div>

															<div className="flex space-x-4">
																<button
																	type="submit"
																	disabled={loading}
																	className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700"
																>
																	{
																		loading ?
																			<svg className="animate-spin h-5 w-5 text-white"
																			     xmlns="http://www.w3.org/2000/svg" fill="none"
																			     viewBox="0 0 24 24">
																				<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
																				        strokeWidth="4"></circle>
																				<path className="opacity-75" fill="currentColor"
																				      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
																			</svg>
																			: 'Save Changes'
																	}
																</button>
																<button
																	type="button"
																	onClick={() => setEditMode(false)}
																	className="flex items-center justify-center rounded-md border border-transparent bg-gray-300 dark:bg-gray-700 px-4 py-2 text-base font-medium text-gray-900 dark:text-white hover:bg-gray-400 dark:hover:bg-gray-600"
																>
																	Cancel
																</button>
															</div>
														</div>
													</form>
												)}
											</div>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
			{ notificationComponent }
		</Transition>
	)
}
