import React from "react";
import {Listing, MarketplacePlatform} from '../utils/types';
import amazonIcon from "../images/Amazon.svg";
import ebayIcon from "../images/eBay.svg";
import mercariIcon from "../images/Mercari.svg";
import grailedIcon from "../images/Grailed.svg";
import etsyIcon from "../images/Etsy.svg";
import facebookIcon from "../images/FacebookMarketplaces.svg";
import shopifyIcon from "../images/Shopify.svg";
import lightSpeedIcon from "../images/lightspeed.svg";
import stockXIcon from "../images/stockx.svg";
import goatIcon from "../images/goat-rounded.svg";
import depopIcon from "../images/Depop.svg";
import woocommerceIcon from "../images/woocommerce-icon-svgrepo-com.svg";
import {listingsStore} from "../state/Listing";
import Tooltip from "./Tooltip";

export const marketplaceImages: { [key: number]: any } = {
	[MarketplacePlatform.EBAY]: {
		src: ebayIcon,
		alt: 'eBay',
	},
	[MarketplacePlatform.GRAILED]: {
		src: grailedIcon,
		alt: 'Grailed',
	},
	[MarketplacePlatform.MERCARI]: {
		src: mercariIcon,
		alt: 'Mercari',
	},
	[MarketplacePlatform.SHOPIFY]: {
		src: shopifyIcon,
		alt: 'Shopify',
	},
	[MarketplacePlatform.STOCKX]: {
		src: stockXIcon,
		alt: 'StockX',
	},
	[MarketplacePlatform.LIGHTSPEED]: {
		src: lightSpeedIcon,
		alt: 'Lightspeed',
	},
	[MarketplacePlatform.GOAT]: {
		src: goatIcon,
		alt: 'GOAT',
	},
	[MarketplacePlatform.AMAZON]: {
		src: amazonIcon,
		alt: 'Amazon',
	},
	[MarketplacePlatform.ETSY]: {
		src: etsyIcon,
		alt: 'Etsy',
	},
	[MarketplacePlatform.FACEBOOK]: {
		src: facebookIcon,
		alt: 'Facebook',
	},
	[MarketplacePlatform.DEPOP]: {
		src: depopIcon,
		alt: 'Depop',
	},
	[MarketplacePlatform.WOOCOMMERCE]: {
		src: woocommerceIcon,
		alt: 'WooCommerce',
	},
};

interface ListingImageCloudProps {
	marketplaces: MarketplacePlatform[];
	inventoryId?: string;
	showListingDisplay?: (listing:Listing) => void;
}

export default function ListingImageCloud({ marketplaces, inventoryId, showListingDisplay }: ListingImageCloudProps) {

	const onImageClick = (platform:MarketplacePlatform) => {
		if(!inventoryId || !showListingDisplay) return;
		const listing = listingsStore.listings.filter((item) => item.inventoryId === inventoryId && item.platform === platform)
		if(!listing.length) return;
		showListingDisplay(listing[0]);
	}

	return (
		<div className="isolate flex flex-wrap">
			{marketplaces.map((platform) => {
				const image = marketplaceImages[platform];
				return image ? (
					<Tooltip key={platform} text={"Click to view listing"}>
						<img key={platform} onClick={() => {onImageClick(platform)}} title={"click to view listing"} src={image.src} alt={image.alt} className="h-6 w-6 cursor-pointer rounded-full" />
					</Tooltip>
				) : null;
			})}
		</div>
	);
}
