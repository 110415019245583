import React from 'react';

interface PlatformEmailUsernamePasswordConfigProps {
	onSubmit: (formData: { email: string; password: string }) => void;
	header: string;
}

export default function PlatformEmailUsernamePasswordConfig({ onSubmit, header }: PlatformEmailUsernamePasswordConfigProps) {
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries()) as { email: string; password: string };
		onSubmit(data);
	};

	return (
		<>
			<div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
				<div className="w-full max-w-sm space-y-10">
					<h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">{header}</h2>
					<form onSubmit={handleSubmit} className="space-y-6">
						<div className="relative -space-y-px rounded-md shadow-sm">
							<div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300 dark:ring-gray-700" />
							<div>
								<label htmlFor="email-address" className="sr-only">
									Email address
								</label>
								<input
									id="email-address"
									name="email"
									type="email"
									required
									placeholder="Email address"
									autoComplete="email"
									className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-800 ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
							<div>
								<label htmlFor="password" className="sr-only">
									Password
								</label>
								<input
									id="password"
									name="password"
									type="password"
									required
									placeholder="Password"
									autoComplete="current-password"
									className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 dark:text-gray-100 bg-gray-100 dark:bg-gray-800 ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
						<button
							type="submit"
							className="w-full flex justify-center rounded-md bg-indigo-600 py-1.5 px-3 text-xs font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
						>
							Submit
						</button>
					</form>
				</div>
			</div>
		</>
	);
}
