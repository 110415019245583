import axiosInstance from './server';
import { User, CreateUserRequest, UpdateUserRequest, GetUserResponse, Plan } from 'app-ts-types/users';

// Get a user by ID
export const getUserById = async (id: string): Promise<GetUserResponse> => {
	const response = await axiosInstance.get<GetUserResponse>(`/user/${id}`);
	return response.data;
};

// Create a new user
export const createUser = async (data: CreateUserRequest): Promise<GetUserResponse> => {
	const response = await axiosInstance.post<GetUserResponse>('/user', data);
	return response.data;
};

// Update a user by ID
export const updateUser = async (id: string, data: User): Promise<GetUserResponse> => {
	const response = await axiosInstance.put<GetUserResponse>(`/user/${id}`, data);
	return response.data;
};

// Delete a user by ID
export const deleteUser = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/user/${id}`);
};

// Update user plan
export const updatePlan = async (plan:any) => {
	const response = await axiosInstance.put('/plan', plan);
	return response.data;
};

// Update user addons
export const updateUserAddons = async (addons: any[]) => {
	const response = await axiosInstance.put('/addon', addons);
	return response.data;
};

// Update user addons
export const getUserInvoices = async () => {
	const response = await axiosInstance.get('/invoices');
	return response.data;
};
