'use client'

import {useEffect, useState} from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

interface NotificationProps {
	title: string;
	message: string;
	isError: boolean;
	autoClose: boolean;
	notificationCloseCallback?: () => void;
}

export default function NotificationPopup(props: NotificationProps) {
	const [show, setShow] = useState(true);
	return (
		<>
			{/* Global notification live region, render this permanently at the end of the document */}
			<div
				aria-live="assertive"
				className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition show={show}>
						<div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0 pt-0.5">
										<img
											alt=""
											src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
											className="h-10 w-10 rounded-full"
										/>
									</div>
									<div className="ml-3 w-0 flex-1">
										<p className="text-sm font-medium text-gray-900">{props.title}</p>
										<p className="mt-1 text-sm text-gray-500">{props.message}.</p>
										<div className="mt-4 flex">
											<button
												type="button"
												className="inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											>
												Accept
											</button>
											<button
												type="button"
												className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
											>
												Decline
											</button>
										</div>
									</div>
									<div className="ml-4 flex flex-shrink-0">
										<button
											type="button"
											onClick={() => {
												setShow(false)
											}}
											className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										>
											<span className="sr-only">Close</span>
											<XMarkIcon aria-hidden="true" className="h-5 w-5" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</>
	)
}

export function NotificationPopupComponent(props: NotificationProps) {
	const [show, setShow] = useState(true);

	useEffect(() => {
		if (props.autoClose) {
			setTimeout(() => {
				setShow(false);
				props.notificationCloseCallback && props.notificationCloseCallback();
			}, 5000);
		}
	}, []);

	let notificationIcon = <CheckCircleIcon aria-hidden="true" className={`h-6 w-6 text-green-400`} />;
	if (props.isError) {
		notificationIcon = <XCircleIcon aria-hidden="true" className={`h-6 w-6 text-red-400`} />;
	}

	return (
		<>
			{/* Global notification live region, render this permanently at the end of the document */}
			<div
				aria-live="assertive"
				className="pointer-events-none z-[101] shadow-lg fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition show={show}>
						<div
							className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 transition 
                bg-white dark:bg-gray-900 dark:border dark:border-gray-700
                data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0`}
						>
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0">{notificationIcon}</div>
									<div className="ml-3 w-0 flex-1 pt-0.5">
										<p className="text-sm font-medium text-gray-900 dark:text-white">{props.title}</p>
										<p className="mt-1 text-sm text-gray-500 dark:text-gray-300">{props.message}.</p>
									</div>
									<div className="ml-4 flex flex-shrink-0">
										<button
											type="button"
											onClick={() => {
												setShow(false);
												props.notificationCloseCallback && props.notificationCloseCallback();
											}}
											className="inline-flex rounded-md bg-white dark:bg-gray-900 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										>
											<span className="sr-only">Close</span>
											<XMarkIcon aria-hidden="true" className="h-5 w-5" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</>
	);
}
