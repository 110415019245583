import React from 'react';
import noMessagesImage from "../images/no-chats.png";
import EmptyComponent from "../components/Empty";

const Chats = () => {
	return (
		<div className="flex flex-col md:flex-row min-h-screen">
			{/* Sidebar */}
			<div className="hidden md:flex md:flex-col md:w-1/4 border-b md:border-b-0 md:border-r border-gray-200 dark:border-gray-700 p-4">
				{/* Chat Users List Header */}
				<h2 className="text-xl font-semibold mb-4">Chats</h2>
				{/* Empty Chat Users List */}
				<p className="text-gray-500">No chats available</p>
			</div>

			{/* Main Content */}
			<div className="flex-grow p-4 flex items-center justify-center">
				<EmptyComponent
					imageSrc={noMessagesImage}
					headerText={"No Messages"}
					descriptionText={"Select a chat to start messaging"}
				/>
			</div>
		</div>
	);
};

export default Chats;
