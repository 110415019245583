import axiosInstance from './server';
import { ImportInventoryRequest, ExportInventoryResponse } from 'app-ts-types/import-export';

// Import inventory
export const importInventory = async (data: FormData): Promise<any> => {
	const response = await axiosInstance.post('/import', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data;
};

// Export inventory
export const exportInventory = async (): Promise<ExportInventoryResponse> => {
	const response = await axiosInstance.get<ExportInventoryResponse>('/export');
	return response.data;
};
