import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import noMarketPlaceImage from "../images/no-marketplace-found.png";
import EmptyComponent from "../components/Empty";
import {listingsStore} from "../state/Listing";
import {Listing} from "app-ts-types/listings";
import {marketplaceImages} from "../components/ListingImageCloud";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {deleteListing, getListings} from "../api/listings";
import Loader from "../components/Loading";
import {ChevronDownIcon, ChevronUpIcon, EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import {statuses} from "../utils/data";
import ListingImage from "../components/Image";
import {Menu, Transition} from "@headlessui/react";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import {observer} from "mobx-react-lite";
import InventoryItem from "../components/InventoryItem";
import ListingItem from "../components/ListingItem";
import {deleteInventory} from "../api/inventories";
import {inventoryStore} from "../state/Inventory";
import {Inventory as InventoryType} from "../utils/types";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

export const SortableHeader = ({field, label, sortField, sortOrder, onSort}: any) => {
	return (
		<th
			scope="col"
			className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter cursor-pointer"
			onClick={() => onSort(field)}
		>
			<div className="flex items-center">
				{label}
				{sortField === field && (
					sortOrder === 'asc' ? (
						<ChevronUpIcon className="ml-2 h-4 w-4 text-gray-900 dark:text-white"/>
					) : (
						<ChevronDownIcon className="ml-2 h-4 w-4 text-gray-900 dark:text-white"/>
					)
				)}
			</div>
		</th>
	);
};

const Marketplaces = observer(({
	                               handleCreateInventory,
	                               handleCreateCollection,
	                               handleCreateListing
                               }: {
	handleCreateInventory: () => void;
	handleCreateCollection: () => void;
	handleCreateListing: (listing?: Listing) => void;
}) => {
	const checkbox = useRef(null);
	const [checked, setChecked] = useState(false);
	const [loadListings, setLoadListings] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [editListing, setEditListing] = useState(false);
	const [selectedItems, setSelectedItems] = useState<Listing[]>([]);
	const [deletingItems, setDeletingItems] = useState<Listing[]>([]);
	const [currentListing, setCurrentListing] = useState<Listing | null>(null);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	useEffect(() => {
		if (listingsStore.listings.length) return;
		setLoadListings(true);
		getListingObjects();
	}, []);

	useLayoutEffect(() => {
		const isIndeterminate = selectedItems.length > 0 && selectedItems.length < listingsStore.filteredAndSortedListings.length;
		setChecked(selectedItems.length === listingsStore.filteredAndSortedListings.length);
		setIndeterminate(isIndeterminate);
		if (checkbox.current) {
			// @ts-ignore
			checkbox.current.indeterminate = isIndeterminate;
		}
	}, [selectedItems, listingsStore.filteredAndSortedListings.length]);

	function toggleAll() {
		setSelectedItems(checked || indeterminate ? [] : listingsStore.filteredAndSortedListings);
		setChecked(!checked && !indeterminate);
		setIndeterminate(false);
	}

	const getListingObjects = () => {
		getListings()
			.then((res: any) => {
				if (!res.isError) {
					listingsStore.setListings(res.data);
					setLoadListings(false);
				}
			})
			.catch((err: any) => {
				console.log(err);
				setLoadListings(false);
			})
	}

	const deleteInventoryRequest = async (id: string) => {
		return deleteListing(id).then((res: any) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: false,
				title: `Listing Removed`,
				message: `Listing object successfully removed`
			});
			listingsStore.deleteListing(id)
		}).catch((err: any) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Listing Delete error`,
				message: err.message
			})
		});
	}

	const listingItemClick = (item: any) => {
		setCurrentListing(item);
	};

	const DeListlistingItemClick = (item:any) => {
		console.log(item);
	}

	const listingItemClickPopupOnClose = () => {
		setEditListing(false);
		setCurrentListing(null);
	}

	const editClick = (listing: Listing) => {
		setEditListing(true);
		setCurrentListing(listing);
	}

	const deleteClick = async (listing: Listing) => {
		setDeletingItems([...deletingItems, listing]);
		await deleteInventoryRequest(listing.id)
		setDeletingItems(deletingItems.filter((dit) => dit !== listing));
		setCurrentListing(null);
	}

	const handleSort = (field: string) => {
		if (listingsStore.sortOption.field === field) {
			listingsStore.setSortOption({
				...listingsStore.sortOption,
				direction: listingsStore.sortOption.direction === 'asc' ? 'desc' : 'asc'
			});
		} else {
			listingsStore.setSortOption({field, direction: "asc"});
		}
	};

	const handlePrevPage = () => {
		if (listingsStore.currentPage > 1) {
			listingsStore.setCurrentPage(listingsStore.currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (listingsStore.currentPage < listingsStore.totalPages) {
			listingsStore.setCurrentPage(listingsStore.currentPage + 1);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}

	const deleteSelectedItems = async (e: any) => {
		e.preventDefault();
		try {
			setDeletingItems(selectedItems)
			// Wait for all delete operations to complete
			await Promise.all(selectedItems.map((lst) => deleteListing(lst.id)));
			// Update the inventory store and clear selected items
			listingsStore.setListings(listingsStore.filteredAndSortedListings.filter((item: Listing) => !selectedItems.includes(item)));
			setSelectedItems([]);
		} catch (error) {
			console.error("Error deleting items:", error);
			// Handle error if needed
		}
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	const component = <EmptyComponent
		imageSrc={noMarketPlaceImage}
		headerText={"Listings"}
		descriptionText={"You have not listed any products"}
		actionText={"create new listing"}
		onActionClick={handleCreateListing}
	/>

	let listingsComponent;

	if (listingsStore.paginatedListings.length === 0) {
		listingsComponent = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				{component}
			</div>
		);
	} else {
		listingsComponent = (
			<div className="bg-white dark:bg-gray-900 sm:rounded-lg">
				<div className={"flex space-x-4"}>
					{selectedItems.length > 0 && (
						<>
							<div
								className="left-14 top-0 flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
										<span
											className="text-sm font-medium text-gray-900 dark:text-white">{selectedItems.length} selected</span>
							</div>
							<div
								className="left-14 top-0 z-[20] flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
								<button
									type="button"
									className="inline-flex items-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-900"
								>
									Bulk Delist
								</button>
								{/*@ts-ignore*/}
								<button onClick={deleteSelectedItems}
								        disabled={Boolean(deletingItems.length)}
								        type="button"
								        className="inline-flex items-center !text-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-900"
								>
									{Boolean(deletingItems.length) ? (
										<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
										     viewBox="0 0 24 24">
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											        strokeWidth="4"></circle>
											<path className="opacity-75" fill="currentColor"
											      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
										</svg>
									) : (
										"Delete all"
									)
									}
								</button>
							</div>
						</>
					)}
				</div>
				<table className="w-full whitespace-nowrap dark:border dark:border-gray-700 border rounded-lg text-left">
					<thead
						className="border-b border-gray-200 dark:border-gray-700 rounded-lg text-sm font-semibold text-gray-900 dark:text-white">
					<tr>
						<th scope="col" className="relative px-7 sm:w-12 sm:px-6">
							<input
								type="checkbox"
								className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600"
								ref={checkbox}
								checked={checked}
								onChange={toggleAll}
							/>
						</th>
						<th className="py-2 px-4">Image</th>
						<SortableHeader
							field="platform"
							label="Marketplace"
							sortField={listingsStore.sortOption.field}
							sortOrder={listingsStore.sortOption.direction}
							onSort={handleSort}
						/>
						{/*<th className="py-2 px-4">Marketplace</th>*/}
						<SortableHeader
							field="title"
							label="Title & Description"
							sortField={listingsStore.sortOption.field}
							sortOrder={listingsStore.sortOption.direction}
							onSort={handleSort}
						/>
						<SortableHeader
							field="price"
							label="Price"
							sortField={listingsStore.sortOption.field}
							sortOrder={listingsStore.sortOption.direction}
							onSort={handleSort}
						/>
						<th>Sizes</th>
						<th className="py-2 px-4">Quantity</th>
						<SortableHeader
							field="status"
							label="Status"
							sortField={listingsStore.sortOption.field}
							sortOrder={listingsStore.sortOption.direction}
							onSort={handleSort}
						/>
						<th className="py-2 px-4 text-right">Actions</th>
					</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 dark:divide-gray-700 font-lato-regular ">
					{listingsStore.paginatedListings.map((listing, index) => (
						<tr key={`${listing.id}-${listing.imageUrls.length}`} className="flex flex-col sm:table-row">
							<td className="relative px-7 sm:w-12 sm:px-6">
								{/*// @ts-ignore*/}
								{selectedItems.includes(listing) && (
									<div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"/>
								)}
								{/*// @ts-ignore*/}
								<input checked={selectedItems.includes(listing)}
								       type="checkbox"
								       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600"
								       value={listing.id}
								       onChange={(e) =>
									       // @ts-ignore*
									       setSelectedItems(e.target.checked ? [...selectedItems, listing] : selectedItems.filter((p) => p !== listing))
								       }
								/>
							</td>
							<td onClick={() => {
								listingItemClick(listing)
							}} className="py-4 cursor-pointer px-4">
								<ListingImage title={listing.SKU} imageUrl={listing.imageUrls[0]}/>
							</td>
							<td onClick={() => {
								listingItemClick(listing)
							}} className="py-4 cursor-pointer px-4">
								<div className={"flex items-center gap-2"}>
									<img src={marketplaceImages[listing.platform]?.src} alt={marketplaceImages[listing.platform]?.alt}
									     className="h-6 w-6"/>
									<span className="text-gray-800 dark:text-gray-200">{marketplaceImages[listing.platform]?.alt}</span>
								</div>
							</td>
							<td onClick={() => {
								listingItemClick(listing)
							}} className="py-4 cursor-pointer px-4 max-w-[17rem] lg:max-w-[10rem]">
								<div className="text-gray-900 dark:text-white font-medium whitespace-break-spaces">{listing.title}</div>
								<p className="text-gray-500 dark:text-gray-400 whitespace-break-spaces">{listing.description}</p>
							</td>
							<td onClick={() => {
								listingItemClick(listing)
							}} className="py-4 cursor-pointer px-4">
								{listing.currencyCode} {listing.price}
							</td>
							<td>
								<ul className="flex flex-wrap gap-2">
									{listing.sizes.map((size, index) => (
										<li key={index} className="bg-brightPurple text-white px-2 py-1 rounded">
											{size.size || "N/A"}
										</li>
									))}
								</ul>
							</td>
							<td className="py-4 px-4">
								{listing.quantity}
							</td>
							<td className="py-4 px-4">
                <span className={classNames(statuses[listing.status], 'rounded px-2 py-1 text-xs font-medium')}>
                  {listing.status}
                </span>
							</td>
							<td className="py-4 px-4 text-right">
							<Menu as="div" className="relative inline-block text-left">
									<div>
										{deletingItems.includes(listing) ? (
											<svg className="animate-spin h-5 w-5 text-deepPurple" xmlns="http://www.w3.org/2000/svg"
											     fill="none"
											     viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
											<Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
												<span className="sr-only">Open options</span>
												<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
											</Menu.Button>
										)}
									</div>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-lg dark:border dark:border-gray-700 bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															listingItemClick(listing)
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														View
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															editClick(listing)
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														Edit
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															DeListlistingItemClick(listing)
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														DeList
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															deleteClick(listing)
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														Delete
													</button>
												)}
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</td>
						</tr>
					))}
					</tbody>
				</table>
				<div className="flex items-center justify-center space-x-4 mt-4">
					<button
						onClick={handlePrevPage}
						disabled={listingsStore.currentPage === 1}
						className={`p-2 cursor-pointer rounded-full ${listingsStore.currentPage === 1 ? 'text-gray-400' : 'text-gray-700 dark:text-gray-300'} hover:bg-gray-200 dark:hover:bg-gray-700`}
					>
						<ChevronLeftIcon className="h-6 w-6"/>
					</button>
					<span className="text-gray-900 dark:text-gray-100">
			        Page {listingsStore.currentPage} of {listingsStore.totalPages}
			      </span>
					<button
						onClick={handleNextPage}
						disabled={listingsStore.currentPage === listingsStore.totalPages}
						className={`p-2 cursor-pointer rounded-full ${listingsStore.currentPage === listingsStore.totalPages ? 'text-gray-400' : 'text-gray-700 dark:text-gray-300'} hover:bg-gray-200 dark:hover:bg-gray-700`}
					>
						<ChevronRightIcon className="h-6 w-6"/>
					</button>
				</div>
			</div>
		)
	}

	if (loadListings) {
		listingsComponent = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<Loader/>
			</div>
		);
	}

	return (
		<main>
			{notificationComponent}
			{currentListing &&
				<ListingItem edit={editListing} del={deletingItems.includes(currentListing)} listing={currentListing}
				             onClose={listingItemClickPopupOnClose} deleteClick={deleteClick}
				             editClick={editClick}/>}
			<div className="space-y-16">
				<div
					className={"flex md:flex-row flex-col lg:space-x-4 space-y-4 lg:space-y-0 mx-auto w-full justify-between px-4 sm:px-6 lg:px-8"}>
					<h1 className={"text-3xl font-bold"}>Listings & Marketplaces</h1>
					<button
						onClick={() => {
							handleCreateListing();
						}}
						className="mt-6 rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>
						Create New Listing
					</button>
				</div>
				<div
					className={"grid grid-cols-1 lg:grid-cols-5 items-start lg:space-x-4 space-y-4 lg:space-y-0 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"}>
					<div className={"space-y-4 self-start lg:col-span-4"}>
						{listingsComponent}
					</div>
					<div
						className="overflow-hidden border bg-white dark:bg-gray-800/10 dark:border-gray-700 sm:rounded-lg">
						<div className="px-4 py-5 sm:p-6 h-full">
							<div className="flex items-center justify-between">
								<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Marketplaces</h2>
							</div>
							<ul className="mt-4 space-y-4">
								{Object.entries(marketplaceImages).map(([key, {src, alt}]) => (
									<li key={key}
									    className="border cursor-pointer dark:border-gray-700 overflow-hidden bg-gray-200 dark:bg-gray-800 px-4 py-4 shadow sm:rounded-md sm:px-2 flex justify-between">
										<div className="flex items-center space-x-2">
											<img src={src} alt={alt} className="h-8 w-8 rounded-full"/>
											<span className="text-gray-900 dark:text-gray-100">{alt}</span>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
});

export default Marketplaces;
