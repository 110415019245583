import axiosInstance from './server';
import { Collection, CreateCollectionRequest, UpdateCollectionRequest, GetCollectionResponse, GetCollectionsResponse } from 'app-ts-types/collections';

// Get a collection by ID
export const getCollectionById = async (id: string): Promise<GetCollectionResponse> => {
	const response = await axiosInstance.get<GetCollectionResponse>(`/collection/${id}`);
	return response.data;
};

// Get a collections
export const getCollections = async (): Promise<GetCollectionsResponse> => {
	const response = await axiosInstance.get<GetCollectionsResponse>(`/collection`);
	return response.data;
};

// Create a new collection
export const createCollection = async (data: Collection): Promise<GetCollectionResponse> => {
	const response = await axiosInstance.post<GetCollectionResponse>('/collection', data);
	return response.data;
};

// Update a collection by ID
export const updateCollection = async (id: string, data: Collection): Promise<GetCollectionResponse> => {
	const response = await axiosInstance.put<GetCollectionResponse>(`/collection/${id}`, data);
	return response.data;
};

// Delete a collection by ID
export const deleteCollection = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/collection/${id}`);
};
