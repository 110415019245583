import React, {useEffect, useState} from 'react';
import {Dialog, Menu, Switch, Transition} from '@headlessui/react';
import {Categories, categoriesFromJSON, categoriesToJSON, Listing, MarketplacePlatform} from '../utils/types';
import {BuildingStorefrontIcon, ChevronRightIcon, PhotoIcon} from '@heroicons/react/24/outline';
import {NotificationPopupComponent} from "./NotificationPopup";
import {userStore} from "../state/User";
import {generateUUID, productSizes} from "../utils/data";
import {createListing, updateListing} from "../api/listings";
import {listingsStore} from "../state/Listing";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {PlusCircleIcon, XCircleIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {calculateTotalQuantity, determineStockLevel, initialInventory as initialInventoryData} from "./CreateInventory";
import {marketplaceImages} from "./ListingImageCloud";
import {Inventory, Statuses} from "../utils/types";
import {createInventory, updateInventory} from "../api/inventories";
import {inventoryStore} from "../state/Inventory";
import ImageUploader from "./ImgUploader";
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';


const initialListing: Listing = {
	"platform": MarketplacePlatform.EBAY,
	"id": '',
	"user": '',
	"title": '',
	"description": '',
	"price": 0,
	"currencyCode": 'USD',
	"quantity": 1,
	"imageUrls": [],
	"category": '',
	"condition": 'new',
	"seller": '',
	"location": '',
	"shipping": undefined,
	"scheduleDateTime": 0,
	"createdAt": Date.now(),
	"updatedAt": Date.now(),
	"status": 'LISTED',
	"inventoryId": '',
	"listingUrl": '',
	"tagsAndKeywords": [],
	"SKU": "",
	"brand": "",
	"color": "",
	"sizes": [],
};

// list of fields on the listing object to update
const listingObjectFields = [
	"title",
	"description",
	"price",
	"currencyCode",
	"imageUrls",
	"category",
	"condition",
	"seller",
	"location",
	"shipping",
	"scheduleDateTime",
	"createdAt",
	"updatedAt",
	"status",
	"inventoryId",
	"listingUrl",
	"tagsAndKeywords",
	"SKU",
	"brand",
	"color",
	"sizes",
]

const ListingForm = ({ initialData=initialListing, initialInventory=initialInventoryData, onClose, isUpdateInventory }: { initialData?: Listing, initialInventory?:Inventory, onClose: () => void; isUpdateInventory?:boolean; }) => {
	// if there's no size information available, then let's add one for default size selection
	if (!initialData.sizes || !initialData.sizes.length) {
		initialData.sizes = initialInventory.sizes;
	}
	const [listing, setListing] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [selectedPlatforms, setSelectedPlatforms] = useState<MarketplacePlatform[]>([]);
	const [currentPlatform, setCurrentPlatform] = useState<number>(-1);
	const [customMarketPlaceListing, setCustomMarketPlaceListing] = useState<{ [key:number]:Listing}>({});
	const [currentSizeIndex, setCurrentSizeIndex] = useState(0);
	const [sizeType, setSizeType] = useState<'US' | 'EU'>('US');
	const [openCategory, setOpenCategory] = useState<string | null>(null);
	const [openMenu, setOpenMenu] = useState(false);

	useEffect(() => {
		// if we're updating an inventory object
		if(isUpdateInventory) {
			const listings = listingsStore.listings.filter((item) => item.inventoryId === initialInventory.id)
			setSelectedPlatforms(listings.map((l:Listing) => l.platform));
			if (listings.length > 0) {
				const customMapping: { [key: number]: Listing } = {};
				listings.forEach((listing) => {
					customMapping[listing.platform] = listing;
				});
				setCustomMarketPlaceListing(customMapping);
			}
		}
	}, []);

	useEffect(() => {
		// if it's the default marketplace and
		// there are some selected platforms
		if (currentPlatform === -1) {
			if(selectedPlatforms.length) {
				const cachedMarketPlaceUpdates = mergeListings(listing);
				// console.log(cachedMarketPlaceUpdates);
				setCustomMarketPlaceListing((prev) => ({
					...prev,
					...cachedMarketPlaceUpdates,
				}))
			}
			setCustomMarketPlaceListing((prev) => ({
				...prev,
				[currentPlatform]: listing
			}))
		}
	}, [listing]);

	const mergeListings = (updatedDefaultListing: Listing): { [key: number]: Listing } => {
		const tempCachedListingObjects: { [key: number]: Listing } = {};

		for (let platform of selectedPlatforms) {
			// Initialize tempCachedListingObjects[platform] properly
			tempCachedListingObjects[platform] = cloneDeep(customMarketPlaceListing[platform] || {});

			for (let key of listingObjectFields) {
				// Access platform and default values safely
				// @ts-ignore
				const platformValue = customMarketPlaceListing[platform]?.[key];
				// @ts-ignore
				const defaultValue = customMarketPlaceListing[-1]?.[key]; // Ensure customMarketPlaceListing[-1] exists
				// @ts-ignore
				const updatedValue = updatedDefaultListing[key];

				// Handle 'sizes' and update 'quantity' when 'sizes' is updated
				if (key === 'sizes') {
					if (isEmpty(platformValue) || isEqual(platformValue, defaultValue)) {
						// @ts-ignore
						tempCachedListingObjects[platform]['sizes'] = cloneDeep(updatedValue);
						// @ts-ignore
						tempCachedListingObjects[platform]['quantity'] = updatedDefaultListing['quantity'];
					}
					continue;
				}

				// Handle 'shipping' with deep comparison
				if (key === 'shipping') {
					if (isEmpty(platformValue) || isEqual(platformValue, defaultValue)) {
						// @ts-ignore
						tempCachedListingObjects[platform]['shipping'] = cloneDeep(updatedValue);
					}
					continue;
				}

				// Handle 'imageUrls' with deep comparison
				if (key === 'imageUrls') {
					if (isEmpty(platformValue) || isEqual(platformValue, defaultValue)) {
						tempCachedListingObjects[platform]['imageUrls'] = cloneDeep(updatedValue);
					}
					continue;
				}

				// For other keys
				// Decide whether to update the key
				if ((!platformValue || platformValue === defaultValue) && updatedValue) {
					// @ts-ignore
					tempCachedListingObjects[platform][key] = updatedValue;
				}
				// If the platform has a custom value that's different from the default, we leave it unchanged
			}
		}
		return tempCachedListingObjects;
	};

	const handlePlatformChange = (platform: MarketplacePlatform) => {
		setSelectedPlatforms((prev) => {
			if (prev.includes(platform)) {
				if (customMarketPlaceListing[platform]) {
					setCustomMarketPlaceListing((fields) => {
						const { [platform]: _, ...rest } = fields; // Destructure to exclude the platform
						return rest;
					});
				}
				return prev.filter((p) => p !== platform);
			} else {
				// Initialize custom fields for new platform
				if (!customMarketPlaceListing[platform]) {
					setCustomMarketPlaceListing((fields) => ({
						...fields,
						[platform]: { ...cloneDeep(customMarketPlaceListing[-1]), platform, id: "" }, // Use the general form as default
					}));
				}
				return [...prev, platform];
			}
		});
	};

	const currentPlatformChange = (platform: number) => {
		// Step 1: Create updated customMarketPlaceListing
		const updatedCustomMarketPlaceListing = {
			...customMarketPlaceListing,
			[currentPlatform]: cloneDeep(listing), // Cache under currentPlatform
		};

		// Step 2: Get the new listing for the selected platform
		const newListing = cloneDeep(
			updatedCustomMarketPlaceListing[platform] ||
			customMarketPlaceListing[-1] || // Fallback to default listing if not available
			{}
		);

		// Step 3: Update the states
		setCustomMarketPlaceListing(updatedCustomMarketPlaceListing);
		setListing(newListing);
		setCurrentPlatform(platform);
	};

	function mergeListingToInventory(listing: Listing, inventoryId:string): Inventory {
		// Create a copy of the inventory to avoid mutating the original object
		const mergedInventory = { ...initialInventory };
		// Overlapping fields where Listing takes precedence
		mergedInventory.id = inventoryId;
		mergedInventory.user = userStore.getUser()?.id as string;
		mergedInventory.SKU = initialInventory.SKU || listing.SKU;
		mergedInventory.brand = initialInventory.brand || listing.brand;
		mergedInventory.color = listing.color || initialInventory.color;
		mergedInventory.sizes = !isUpdateInventory && listing.sizes && listing.sizes.length > 0 ? listing.sizes : initialInventory.sizes;
		mergedInventory.description = listing.description || initialInventory.description;
		// Map Listing.title to Inventory.name
		mergedInventory.name = listing.title || initialInventory.name;
		// Use the first image URL from Listing.imageUrls for Inventory.imageUrl
		mergedInventory.imageUrl = listing.imageUrls && listing.imageUrls.length > 0 ? listing.imageUrls[0] : initialInventory.imageUrl;
		// Map Listing.quantity to Inventory.totalQuantity
		mergedInventory.totalQuantity = listing.quantity || initialInventory.totalQuantity;
		// Map Listing.category (string) to Inventory.category (enum)
		mergedInventory.category = listing.category ? categoriesFromJSON(listing.category) : initialInventory.category;
		// the list of selected platforms
		mergedInventory.platform = selectedPlatforms;
		// add the stock level
		mergedInventory.stockLevel = determineStockLevel(mergedInventory.totalQuantity || 0);
		// let's mark this as listed
		mergedInventory.status = Statuses.LISTED
		// let's update the price of the inventory object
		// @ts-ignore
		mergedInventory.purchaseDetails = {...initialInventory.purchaseDetails, price: listing.price || initialInventory.purchaseDetails?.price || 0}
		// Return the merged inventory object
		return mergedInventory;
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		let { name, value } = e.target;
		if(name === "tagsAndKeywords") {
			// @ts-ignore
			value = value.toString().split(", ")
		}
		setListing((prevListing) => ({
			...prevListing,
			[name]: value,
		}));
	};

	const handleImageUpload = (files: string[]) => {
		setListing((prevListing) => ({
			...prevListing,
			imageUrls: files,
		}));
	};

	const createOrUpdateInventoryFunction = async (listing: Listing, inventoryId: string): Promise<any> => {
		const inventoryData = mergeListingToInventory(listing, inventoryId);
		const func = inventoryId ? updateInventory.bind(this, inventoryId) : createInventory;

		return func(inventoryData)
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					if (inventoryId) {
						inventoryStore.updateInventory(res.data);
					} else {
						inventoryStore.addInventory([res.data]);
					}
				}
				return res;
			});
	};

	const createOrUpdateListingFunction = async (listing: Listing, inventoryId: string): Promise<any> => {
		console.log(listing);
		listing.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		listing.updatedAt = currentTime;
		listing.inventoryId = inventoryId;

		const func = listing.id ? updateListing.bind(this, listing.id) : createListing;

		return func({ ...listing, id: listing.id || generateUUID(), createdAt: listing.createdAt || currentTime })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					if (listing.id) {
						listingsStore.updateListing(res.data as Listing);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Listing Updated`,
							message: `Listing information successfully updated`
						});
					} else {
						listingsStore.addListings([res.data]);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Listing Created`,
							message: `Listing information successfully created`
						});
					}
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: `Error ${listing.id ? 'updating' : 'creating'} listing object`,
						message: res.error?.errorMessage || res.message
					});
					console.log(res);
				}
				return res;
			});
	};

	const creationProcess = async():Promise<{[key: string]:any}> => {
		// the inventoryId
		const inventoryId = initialInventory.id || generateUUID();
		// Loop through platforms and create listings
		const marketPlaceIds:number[] = Object.keys(customMarketPlaceListing).map((id) => parseInt(id)).filter((id) => id > -1);
		// the function to create the inventory object
		return createOrUpdateInventoryFunction(customMarketPlaceListing[-1], inventoryId)
			.then((res:any) => {
				if (!res.isError) {
					const createListings = [];
					for (const platformId of marketPlaceIds) {
						createListings.push(createOrUpdateListingFunction(customMarketPlaceListing[platformId], inventoryId));
					}
					return Promise.all(createListings);
				}
				return Promise.reject(res);
			})
	}

	const handleFormSubmit = async () => {
		setLoading(true);
		// Wait for state update
		await new Promise((resolve) => setTimeout(resolve, 10));
		if (!customMarketPlaceListing[-1].title || !customMarketPlaceListing[-1].SKU || !customMarketPlaceListing[-1].brand || customMarketPlaceListing[-1].price <= 0 || !customMarketPlaceListing[-1].seller || !customMarketPlaceListing[-1].location || !customMarketPlaceListing[-1].category) {
			setLoading(false);
			return setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Listing Field Error",
				message: "Title, SKU, Brand, Price, Seller, Location, and Category fields are required with price higher than 0"
			});
		}

		if(!selectedPlatforms.length) {
			setLoading(false);
			return setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Listing Platform Error",
				message: "At least one listing platform is required"
			});
		}

		try {
			await creationProcess()
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Listing Created`,
							message: `Listing information successfully created`
						});
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2000);
					} else {
						setLoading(false);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: true,
							title: "Error updating user object",
							message: res.error?.errorMessage || res.message
						});
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating/creating listing object",
						message: err.message
					});
				});
		} catch (error) {
			console.error('Error creating listing:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleToggleSizeType = () => {
		setSizeType((prevType) => (prevType === 'US' ? 'EU' : 'US'));
	};

	const handleSizeChange = (index: number, field: string, value: string | number) => {
		const newSizes = [...listing.sizes];
		newSizes[index] = {...newSizes[index], [field]: value};
		setListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
		setOpenCategory(null);
		setOpenMenu(false);
	};

	const handleAddSize = () => {
		setListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: [...prevInventory.sizes, {size: '', quantity: 0}],
		}));
	};

	const handleCategoryClick = (category: string) => {
		setOpenCategory(openCategory === category ? null : category);
	};

	const handleRemoveSize = (index: number) => {
		const newSizes = listing.sizes.filter((_: any, i: any) => i !== index);
		setListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
	};

	const handleShippingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		// @ts-ignore
		setListing((prevListing) => ({
			...prevListing,
			shipping: {
				...prevListing.shipping,
				[name]: value,
			},
		}));
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />;
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<Transition.Child
						as={"div"}
						className={"relative"}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel
							className="transform transition-all">
							<div style={{ display: 'grid', gridTemplateColumns: '50px auto'}} className="grid min-h-full w-fit mx-auto items-center justify-center p-4 text-center">
								<div className="self-start mt-8 sticky z-[100] top-10 w-fit">
									<div
										onClick={(e: any) => {
											e.stopPropagation();
											e.preventDefault();
											currentPlatformChange(-1)
										}}
										className={`border hover:bg-opacity-95 cursor-pointer overflow-hidden px-4 py-2 shadow sm:px-2 flex justify-between ${
											currentPlatform === -1 ? 'bg-deepPurple border-deepPurple text-white' : 'text-white bg-deepPurple border-deepPurple'
										}`}>
										<div className="flex items-center space-x-2">
											<BuildingStorefrontIcon className="h-8 w-8 rounded-full"/>
										</div>
									</div>
									{Object.entries(marketplaceImages).map(([key, {src, alt}]) => {
										return (
											<div key={key} onClick={!selectedPlatforms.includes(parseInt(key)) ? undefined : (e: any) => {
												currentPlatformChange(parseInt(key))
												e.stopPropagation();
												e.preventDefault();
											}}
										     className={`border ${!selectedPlatforms.includes(parseInt(key)) ? "dark:border-gray-700 dark:bg-gray-800 bg-gray-200" : "cursor-pointer bg-brightPurple border-deepPurple"} hover:bg-opacity-95 overflow-hidden px-4 py-2 shadow sm:px-2 flex justify-between ${
											     currentPlatform === parseInt(key) ? 'bg-deepPurple border-deepPurple text-white' : ' dark:text-white'
										     }`}>
												<div className="flex items-center space-x-2">
													<img src={src} alt={alt} className="h-8 w-8 rounded-full"/>
												</div>
											</div>
									)})}
								</div>
								<form
									className={"w-full max-w-2xl overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl"}
									onSubmit={(e) => {
										e.preventDefault();
										handleFormSubmit().catch((err) => {
											console.log(err);
										});
									}}>
									<Dialog.Title as="div"
									              className="flex justify-between items-center text-2xl font-medium leading-6 text-gray-900 dark:text-white">
										<span>Create Listing</span>
										<button
											onClick={() => {
												setShowDialog(false);
												onClose();
											}}
											className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
										>
											<XMarkIcon className="h-6 w-6" aria-hidden="true"/>
										</button>
									</Dialog.Title>
									{
										currentPlatform === -1 && (
											<div className="mt-4">
												<label htmlFor="platforms" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Platforms <span className={"text-red-500 font-bold"}>*</span>
												</label>
												<Menu as="div" className="relative inline-block text-left w-full">
													<div>
														<Menu.Button
															className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
															Select
															Platforms {selectedPlatforms.length && " (" + selectedPlatforms.length + " Selected)"}
															<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
														</Menu.Button>
													</div>
													<Transition
														as={React.Fragment}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<Menu.Items
															className="absolute dark:border dark:border-gray-700 left-0 z-[100] w-full origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
															<div className="py-1 max-h-80 overflow-y-auto">
																{Object.keys(MarketplacePlatform)
																	.filter(key => isNaN(Number(key)) && key != "UNRECOGNIZED")
																	.map((key) => (
																		<Menu.Item key={key}>
																			{({active}) => (
																				<div
																					className={`flex items-center px-4 py-2 ${active ? 'bg-gray-100 dark:bg-gray-700' : ''}`}>
																					<input
																						id={key}
																						name={key}
																						type="checkbox"
																						checked={selectedPlatforms.includes(MarketplacePlatform[key as keyof typeof MarketplacePlatform])}
																						onChange={() => handlePlatformChange(MarketplacePlatform[key as keyof typeof MarketplacePlatform])}
																						className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
																					/>
																					<label htmlFor={key}
																					       onChange={() => handlePlatformChange(MarketplacePlatform[key as keyof typeof MarketplacePlatform])}
																					       className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300">
																						{key}
																					</label>
																				</div>
																			)}
																		</Menu.Item>
																	))}
															</div>
														</Menu.Items>
													</Transition>
												</Menu>
											</div>
										)
									}
									<div className="mt-4">
										<label htmlFor="title"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Title <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="title"
												name="title"
												type="text"
												autoComplete="title"
												value={listing.title}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="description"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Description <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
                      <textarea
	                      id="description"
	                      name="description"
	                      autoComplete="description"
	                      value={listing.description}
	                      onChange={handleInputChange}
	                      className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
	                      rows={4}
                      />
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="SKU"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											SKU <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="SKU"
												name="SKU"
												type="text"
												autoComplete="sku"
												value={listing.SKU}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="brand"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Brand <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="brand"
												name="brand"
												type="text"
												autoComplete="brand"
												value={listing.brand}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="color"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Color
										</label>
										<div className="mt-2">
											<input
												id="color"
												name="color"
												type="text"
												autoComplete="color"
												value={listing.color}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="price"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Price <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="price"
												name="price"
												type="number"
												autoComplete="price"
												value={listing.price}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="seller"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Seller <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="seller"
												name="seller"
												type="text"
												autoComplete="seller"
												value={listing.seller}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="location"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Location <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="location"
												name="location"
												type="text"
												autoComplete="location"
												value={listing.location}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="category"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Category <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<select
												id="category"
												name="category"
												value={listing.category}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="">Select a category</option>
												{Object.values(Categories)
													.filter(value => typeof value === 'number') // Ensure we're only dealing with numeric values
													.map((value) => (
														<option key={value} value={categoriesToJSON(value as Categories)}>
															{categoriesToJSON(value as Categories)}
														</option>
													))}
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="currencyCode"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Currency Code
										</label>
										<div className="mt-2">
											<select
												id="currencyCode"
												name="currencyCode"
												value={listing.currencyCode}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="USD">USD ($)</option>
												<option value="EUR">EUR (€)</option>
												<option value="GBP">GBP (£)</option>
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="condition"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Condition
										</label>
										<div className="mt-2">
											<select
												id="condition"
												name="condition"
												value={listing.condition}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="new">New</option>
												<option value="slightly used">Slightly Used</option>
												<option value="used">Used</option>
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="quantity"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Quantity
										</label>
										<div className="mt-2">
											<input
												id="quantity"
												name="quantity"
												type="number"
												autoComplete="quantity"
												value={listing.quantity}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-3 relative">
										<div className="flex w-24 items-center relative justify-between">
											<label htmlFor="sizes"
											       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
												Sizes
											</label>
											<Switch
												checked={sizeType === 'EU'}
												onChange={handleToggleSizeType}
												className={`${sizeType === 'EU' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
											>
												<span className="sr-only">Toggle Size Type</span>
												<span
													className={`${sizeType === 'EU' ? 'translate-x-6' : 'translate-x-1'} inline-block text-[0.5rem] font-avenir-bold h-4 w-4 pt-[0.15rem] transform rounded-full bg-white transition`}>
														{sizeType}
														</span>
											</Switch>
										</div>
										{
											openMenu && <Transition
												as={"div"}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<div
													className="absolute left-0 bottom-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
													<div className="py-1 max-h-60 overflow-y-auto">
														{Object.keys(productSizes).map((category) => (
															<div key={category}>
																<div>
																	<div
																		onClick={(e: any) => {
																			e.preventDefault();
																			handleCategoryClick(category)
																		}}
																		className={`inline-flex w-full px-4 py-2 text-left cursor-pointer text-sm font-semibold text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																	>
																		{category.charAt(0).toUpperCase() + category.slice(1)}
																		<ChevronRightIcon aria-hidden="true"
																		                  className="-mr-1 h-5 w-5 text-gray-400"/>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>
											</Transition>
										}
										{
											openCategory &&
											<Transition
												as={"div"}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<div
													className="absolute left-[225px] -bottom-2.5 z-20 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
													<div className="py-1">
														{productSizes[openCategory][sizeType.toLowerCase()].map((sizeOption) => (
															<div key={sizeOption}>
																<button
																	type="button"
																	onClick={(e) => {
																		e.preventDefault();
																		handleSizeChange(currentSizeIndex, 'size', sizeOption)
																	}}
																	className={`block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																>
																	{sizeOption}
																</button>
															</div>
														))}
													</div>
												</div>
											</Transition>
										}
										{listing.sizes.map((size, index) => (
											<div key={index} className="flex items-center mt-1">
												<div className="relative inline-block text-left w-1/2 mr-2">
													<div>
														<div
															onClick={() => {
																setCurrentSizeIndex(index)
																setOpenMenu(!openMenu)
															}}
															className="inline-flex w-full cursor-pointer justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
															{size.size || 'Select Size'}
															<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
														</div>
													</div>
												</div>
												<input
													type="number"
													name={`quantity-${index}`}
													value={size.quantity}
													onChange={(e) => {
														e.preventDefault();
														handleSizeChange(index, 'quantity', parseInt(e.target.value))
													}}
													className="mr-2 block w-1/2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													placeholder="Quantity"
												/>
												<button
													type="button"
													onClick={() => handleRemoveSize(index)}
													className={`${index === 0 && "invisible"} text-red-500 hover:text-red-700`}
												>
													<XCircleIcon className="h-6 w-6"/>
												</button>
												<button
													type="button"
													onClick={handleAddSize}
													className="text-blue-500 hover:text-blue-700 ml-2"
												>
													<PlusCircleIcon className="h-6 w-6"/>
												</button>
											</div>
										))}
									</div>
									<div className="mt-4">
										<label htmlFor="tagsAndKeywords"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Tags And Keywords
										</label>
										<div className="mt-2">
											<input
												id="tagsAndKeywords"
												name="tagsAndKeywords"
												type="text"
												autoComplete="tags-and-keywords"
												value={listing.tagsAndKeywords.join(", ")}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div>
										<h1 className={"block text-lg pt-4 font-medium leading-6 text-gray-900 dark:text-white"}>Shipping
											Information</h1>
										<div className={"flex mt-4 justify-between space-x-0 md:space-x-4 md:flex-row flex-col"}>
											<div className="md:w-1/2 w-full">
												<label htmlFor="shipping-cost"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Shipping Cost
												</label>
												<div className="mt-2">
													<input
														id="shipping-cost"
														name="cost"
														type="number"
														value={listing.shipping?.cost}
														onChange={handleShippingChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="md:w-1/2 w-full">
												<label htmlFor="estimated-delivery-time"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Estimated Delivery Time (days)
												</label>
												<div className="mt-2">
													<input
														id="estimated-delivery-time"
														name="estimatedDeliveryTime"
														type="number"
														value={listing.shipping?.estimatedDeliveryTime}
														onChange={handleShippingChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
										</div>
										<div className="mt-4">
											<label htmlFor="shipping-method"
											       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
												Shipping Method
											</label>
											<div className="mt-2">
												<input
													id="shipping-method"
													name="method"
													type="text"
													value={listing.shipping?.method}
													onChange={handleShippingChange}
													className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
												/>
											</div>
										</div>
										<div className={"flex mt-4 justify-between space-x-0 md:space-x-4 md:flex-row flex-col"}>
											<div className="md:w-1/2 w-full">
												<label htmlFor="package-weight"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Package Weight
												</label>
												<div className="mt-2">
													<input
														id="package-weight"
														name="packageWeight"
														type="number"
														value={listing.shipping?.packageWeight}
														onChange={handleShippingChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className={"flex justify-between space-x-0 md:space-x-4 md:flex-row flex-col"}>
												<div className="md:w-1/2 w-full">
													<label htmlFor="package-dimension"
													       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
														Package Width
													</label>
													<div className="mt-2">
														<input
															id="package-dimension"
															name="packageDimensionWidth"
															type="number"
															value={listing.shipping?.packageDimensionWidth}
															onChange={handleShippingChange}
															className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
												<div className="md:w-1/2 w-full">
													<label htmlFor="package-dimension"
													       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
														Package Height
													</label>
													<div className="mt-2">
														<input
															id="package-dimension"
															name="packageDimensionHeight"
															type="number"
															value={listing.shipping?.packageDimensionHeight}
															onChange={handleShippingChange}
															className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="mt-4 hidden">
										<label htmlFor="scheduleDateTime"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Schedule Date & Time
										</label>
										<div className="mt-2">
											<input
												id="scheduleDateTime"
												name="scheduleDateTime"
												type="datetime-local"
												autoComplete="schedule-date-time"
												value={new Date(listing.scheduleDateTime).toISOString().slice(0, 16)}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4 hidden">
										<label htmlFor="status"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Status
										</label>
										<div className="mt-2">
											<input
												id="status"
												name="status"
												type="text"
												disabled={true}
												autoComplete="status"
												value={listing.status}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-300 dark:bg-white/40 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4 hidden">
										<label htmlFor="listingUrl"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Listing URL
										</label>
										<div className="mt-2">
											<input
												id="listingUrl"
												name="listingUrl"
												type="url"
												disabled={true}
												autoComplete="listing-url"
												value={listing.listingUrl}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-300 dark:bg-white/40 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="col-span-full mt-4">
										<label htmlFor="imageUrls"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Images
										</label>
										<ImageUploader onUpload={handleImageUpload} maxFiles={25} files={listing.imageUrls}
										               multiple={true}/>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
												     fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												'Create Listing'
											)}
										</button>
									</div>
								</form>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	);
};
export default ListingForm;
