import React from 'react';
import noMessagesImage from "../images/no-messages.png";
import EmptyComponent from "../components/Empty";

const Messages = () => {
	const hasMessages = false; // Set this to true if there are messages

	const handleStartNewChat = () => {
		// Logic to start a new chat
		alert("Starting a new chat!");
	};

	const component = hasMessages ? (
		<div className="flex flex-col items-center justify-center text-center p-6">
			<h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">Messages</h2>
			{/* Placeholder for actual messages */}
			<ul className="w-full max-w-md space-y-4">
				{/* Map over actual messages if available */}
				<li className="p-4 bg-gray-100 dark:bg-gray-800 rounded shadow-md">
					<p className="text-gray-800 dark:text-gray-200 font-medium">User A</p>
					<p className="text-gray-600 dark:text-gray-400 text-sm">Hey! How's it going?</p>
				</li>
				<li className="p-4 bg-gray-100 dark:bg-gray-800 rounded shadow-md">
					<p className="text-gray-800 dark:text-gray-200 font-medium">User B</p>
					<p className="text-gray-600 dark:text-gray-400 text-sm">Are we still on for the meeting?</p>
				</li>
			</ul>
		</div>
	) : (
		<EmptyComponent
			imageSrc={noMessagesImage}
			headerText={"No Messages"}
			descriptionText={"You don’t have any messages yet"}
		/>
	);

	return (
		<div className="flex flex-col items-center justify-center min-h-screen dark:bg-gray-900 p-4">
			<div className="w-full text-center">
				{component}
			</div>
		</div>
	);
};

export default Messages;
