import {SalesRecord} from "app-ts-types/sales";
import {QuantitySizing} from "app-ts-types/generic";
import {Listing, Shipping} from "app-ts-types/listings";
import {Categories, categoriesToJSON, Inventory, Sources, Statuses, StockLevel, MarketplacePlatform} from "../utils/types";


const sampleData = [
	{
		product: "Nike Air Force 1",
		category: Categories.SNEAKERS,
		brand: "Nike",
		image: "https://c.static-nike.com/a/images/t_PDP_1280_v1/f_auto,q_auto:eco/ncpfxo01xs6vfjnt9bnp/air-force-1-07-mens-shoe-XD1fFM.png"
	},
	{
		product: "Adidas Ultraboost 22",
		category: Categories.SNEAKERS,
		brand: "Adidas",
		image: "https://assets.adidas.com/images/w_600,f_auto,q_auto/4d53b1f64a7f4ed68f9fad4f00bce32a_9366/Ultraboost_22_Shoes_White_GX3060_01_standard.jpg"
	},
	{
		product: "Levi's 501 Original Fit Jeans",
		category: Categories.PANTS,
		brand: "Levi's",
		image: "https://lsco.scene7.com/is/image/lsco/005010365-front-pdp"
	},
	{
		product: "Puma Essentials Logo T-Shirt",
		category: Categories.TOPS,
		brand: "Puma",
		image: "https://images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_450,h_450/global/586668/01/fnd/PNA/fmt/png/Essentials-Mens-Logo-Tee"
	},
	{
		product: "Under Armour Rival Fleece Shorts",
		category: Categories.SHORTS,
		brand: "Under Armour",
		image: "https://underarmour.scene7.com/is/image/Underarmour/1366375-001_DEFAULT?rp=standard-0wid"
	},
	{
		product: "The North Face Nuptse Jacket",
		category: Categories.TOPS,
		brand: "The North Face",
		image: "https://images.thenorthface.com/is/image/TheNorthFace/NF0A4R3HJK3_hero"
	},
	{
		product: "Converse Chuck Taylor All Star",
		category: Categories.SNEAKERS,
		brand: "Converse",
		image: "https://www.converse.com/on/demandware.static/-/Sites/default/dw63230d92/images/a_107/M7650_standard.jpg"
	},
	{
		product: "Vans Old Skool",
		category: Categories.SNEAKERS,
		brand: "Vans",
		image: "https://images.vans.com/is/image/Vans/D3HY28-HERO"
	},
	{
		product: "Patagonia Baggies Shorts",
		category: Categories.SHORTS,
		brand: "Patagonia",
		image: "https://www.patagonia.com/dw/image/v2/BBBC_PRD/on/demandware.static/-/Sites-patagonia-master/default/dwffabc8f1/images/hi-res/58034_BLK.jpg"
	},
	{
		product: "Champion Powerblend Hoodie",
		category: Categories.TOPS,
		brand: "Champion",
		image: "https://store.champion.com/on/demandware.static/-/Sites-champion-master-catalog/default/dwfcc6f5e7/images/PCH201/d-PCH201_03137464.jpg"
	}
];

function getRandomDateThisWeek(): Date {
	const now = new Date();
	const dayOfWeek = now.getDay(); // Sunday - Saturday : 0 - 6
	const startOfWeek = new Date(now);
	startOfWeek.setDate(now.getDate() - dayOfWeek); // Set to the start of the week (Sunday)
	startOfWeek.setHours(0, 0, 0, 0); // Set time to midnight

	const endOfWeek = new Date(startOfWeek);
	endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to the end of the week (Saturday)
	endOfWeek.setHours(23, 59, 59, 999); // Set time to the end of the day

	const randomTime = startOfWeek.getTime() + Math.random() * (endOfWeek.getTime() - startOfWeek.getTime());
	return new Date(randomTime);
}

function getRandomPlatform(): MarketplacePlatform {
	const platforms = Object.values(MarketplacePlatform).filter(
		(value) => typeof value === "number" && value !== MarketplacePlatform.UNRECOGNIZED
	) as MarketplacePlatform[];

	const randomIndex = Math.floor(Math.random() * platforms.length);
	return platforms[randomIndex];
}

const getRandomStatus = () => {
	const statusKeys = ["Listed", "Sold", "Created", "Error"];
	return statusKeys[Math.floor(Math.random() * statusKeys.length)];
};

const getRandomPrice = () => (Math.random() * 100 + 20).toFixed(2);

const getRandomSizes = (): QuantitySizing[] => {
	const sizes = ["S", "M", "L", "XL", "XXL"];
	return sizes.map(size => ({
		size,
		quantity: Math.floor(Math.random() * 10) + 1 // Random quantity between 1 and 10
	}));
};

function generateRandomSKU(length: number = 8): string {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let sku = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		sku += characters[randomIndex];
	}
	return sku;
}

const inventories: Inventory[] = sampleData.map((item, index) => {
	const sizes = getRandomSizes();
	const totalQuantity = sizes.reduce((acc, size) => acc + size.quantity, 0);
	const purchasePrice = parseFloat(getRandomPrice());
	const shippingCost = Math.floor(Math.random() * 20) + 5;
	const totalPrice = purchasePrice + shippingCost;

	return {
		id: `inventory-${index + 1}`,
		name: item.product,
		SKU: `SKU-${index + 1}`,
		brand: item.brand,
		color: "Various",
		totalQuantity,
		description: `Description for ${item.product}`,
		sizes,
		purchaseDetails: {
			price: totalPrice,
			shipping: "Standard",
			vatPercentage: 10,
			purchasePlace: "Online Store",
			purchaseDate: new Date().toISOString(),
			orderNumber: `ORD-${index + 1}`
		},
		category: item.category,
		status: Statuses.UNLISTED,
		source: Sources.UNRECOGNIZED,
		platform: [],
		deleted: false,
		createdAt: Date.now(),
		updatedAt: Date.now(),
		collectionId: "",
		outOfStock: false,
		stockLevel: totalQuantity < 10 ? StockLevel.LOW : totalQuantity < 50 ? StockLevel.NORMAL : StockLevel.HIGH,
		user: "user-id-placeholder",
		imageUrl: item.image,
		scheduleListing: 0,
		autoList: false
	};
});

const listings: Listing[] = inventories.map((inventory, index) => {
	const listingPrice = parseFloat(getRandomPrice());

	return {
		id: `listing-${index + 1}`,
		user: inventory.user,
		title: inventory.name,
		description: inventory.description,
		price: listingPrice,
		currencyCode: "USD",
		quantity: inventory.totalQuantity,
		imageUrls: [sampleData[index].image],
		category: categoriesToJSON(inventory.category),
		condition: "new",
		seller: "seller-id-placeholder",
		location: "Location Placeholder",
		shipping: {
			cost: Math.floor(Math.random() * 20) + 5,
			estimatedDeliveryTime: Math.floor(Math.random() * 10) + 1,
			method: "standard",
			packageWeight: 14,
			packageDimensionHeight: 50,
			packageDimensionWidth: 20,
		} as Shipping,
		platform: getRandomPlatform(),
		scheduleDateTime: Date.now(),
		createdAt: Date.now(),
		updatedAt: Date.now(),
		status: getRandomStatus(),
		inventoryId: inventory.id,
		listingUrl: "",
		tagsAndKeywords: [],
		SKU: generateRandomSKU(),
		brand: "",
		color: "",
		sizes: [],
	};
});

export const salesRecords: SalesRecord[] = listings.filter(listing => listing.status === "Sold").map((listing, index) => {
	const salePrice = Math.random() > 0.5 ? listing.price : listing.price - Math.random() * 10; // Some sales lower than listing price

	return {
		id: `sale-${index + 1}`,
		SKU: listing.SKU,
		user: listing.user,
		platform: listing.platform,
		productId: listing.inventoryId,
		listingId: listing.id,
		category: listing.category,
		imageUrl: listing.imageUrls[0] || "",
		productTitle: listing.title,
		price: salePrice,
		currencyCode: listing.currencyCode,
		saleTimestamp: +getRandomDateThisWeek(),
		createdAt: Date.now()
	};
});
