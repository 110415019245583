import React from 'react';
import noNotificationsImage from "../images/no-notifications.png";
import EmptyComponent from "../components/Empty";

const Notifications = () => {
	const hasNotifications = false; // Set this to true if notifications exist

	const handleMarkAllRead = () => {
		// Logic to mark all as read
		alert("All notifications marked as read!");
	};

	const component = hasNotifications ? (
		<div className="flex flex-col items-center justify-center text-center p-6">
			<h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">Notifications</h2>
			{/* Placeholder for actual notifications */}
			<ul className="w-full max-w-md space-y-4">
				{/* Map over actual notifications if available */}
				<li className="p-4 bg-gray-100 dark:bg-gray-800 rounded shadow-md">
					<p className="text-gray-800 dark:text-gray-200 font-medium">Sample Notification 1</p>
					<p className="text-gray-600 dark:text-gray-400 text-sm">This is a placeholder for an actual notification.</p>
				</li>
				<li className="p-4 bg-gray-100 dark:bg-gray-800 rounded shadow-md">
					<p className="text-gray-800 dark:text-gray-200 font-medium">Sample Notification 2</p>
					<p className="text-gray-600 dark:text-gray-400 text-sm">Another sample notification text.</p>
				</li>
			</ul>
		</div>
	) : (
		<EmptyComponent
			imageSrc={noNotificationsImage}
			headerText={"No Notifications"}
			descriptionText={"You're all caught up!"}
		/>
	);

	return (
		<div className="flex flex-col items-center justify-center min-h-screen dark:bg-gray-900 p-4">
			<div className="flex justify-between items-center w-full max-w-md mb-4">
				{hasNotifications && (
					<button
						onClick={handleMarkAllRead}
						className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:underline"
					>
						Mark All as Read
					</button>
				)}
			</div>
			<div className="w-full text-center">
				{component}
			</div>
		</div>
	);
};

export default Notifications;
