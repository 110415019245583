import React, { useState } from 'react';

interface ListingImageProps {
	title: string;
	imageUrl: string;
	className?: string;
	bg?: string;
	fg?: string;
	font?: string;
}

const ListingImage: React.FC<ListingImageProps> = ({ title, imageUrl, className, bg="C4B5FD", fg="7B5EFF", font="oswald"}) => {
	const defaultImage = encodeURI(`https://placehold.co/150x200/${bg}/${fg}?text=${title}&font=${font}`);
	const [imgSrc, setImgSrc] = useState(imageUrl || defaultImage);
	if (!className) { className = "h-12 w-12" }

	const handleError = () => {
		setImgSrc(defaultImage);
	};

	return (
		<img
			alt={title}
			src={imgSrc}
			onError={handleError}
			className={`${className} flex-none rounded-lg object-cover`}
		/>
	);
};

export default ListingImage;
