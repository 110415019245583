// ImageComponent.tsx
import React, {useState} from 'react';
import {XMarkIcon} from "@heroicons/react/24/outline";
import {processImagesOnCDN} from "../api/upload";
import {WWW_BASE_URL} from "../api/server";

interface ImageComponentProps {
	src: string;
	onRemoveBackground?: (newImageSrc: string) => void;
	onRemove?: () => void;
}

const ImageComponent: React.FC<ImageComponentProps> = ({
	                                                       src,
	                                                       onRemoveBackground,
	                                                       onRemove,
                                                       }) => {
	const [imageSrc, setImageSrc] = useState(src);
	const [loading, setLoading] = useState(false);

	const handleRemoveBackground = async () => {
		setLoading(true);
		try {
			const response = await processImagesOnCDN([imageSrc.replace(`${WWW_BASE_URL}/image/`, "")])
			console.log(response);
			if (response.processed_images && response.processed_images.length > 0) {
				const newImageName = response.processed_images[0];
				// Update the image source
				const newSrc = `${WWW_BASE_URL}/image/${newImageName}`;
				setImageSrc(newSrc)
				// if there's a background remove object
				onRemoveBackground && onRemoveBackground(newSrc);
			} else {
				console.error('No processed image returned from API');
			}
		} catch (error) {
			console.error('Error removing background:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="relative group">
			<img src={src} alt="Uploaded" className="w-full h-auto object-cover"/>
			<div
				className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-opacity duration-300 flex items-center justify-center">
				{loading ? (
					<svg
						className="animate-spin h-8 w-8 text-deepPurple"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="4"
						></circle>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
						></path>
					</svg>
				) : (
					<div className={"w-full min-h-16"}>
						{!imageSrc.includes("bg_remove_processed") && (
							<button
								onClick={handleRemoveBackground}
								className="absolute top-2 right-2 bg-deepPurple text-white text-sm py-1 px-3 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
							>
								Remove BG
							</button>
						)}
						{onRemove && (
							<button
								onClick={onRemove}
								className="absolute top-2 left-2 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
							>
								<XMarkIcon className={"h-6 w-6"}/>
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ImageComponent;
