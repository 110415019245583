import React from 'react';

interface EmptyComponentProps {
	imageSrc: string;
	headerText: string;
	descriptionText: string;
	actionText?: string;
	onActionClick?: () => void;
}

const EmptyComponent: React.FC<EmptyComponentProps> = ({
	                                                         imageSrc,
	                                                         headerText,
	                                                         descriptionText,
	                                                         actionText,
	                                                         onActionClick,
                                                         }) => {
	return (
		<div className="flex flex-col items-center justify-center">
			<img src={imageSrc} alt={headerText} className="mb-8 w-80 h-80"/>
			<h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">{headerText}</h1>
			<p className="mt-4 text-lg text-gray-700 dark:text-gray-300">{descriptionText}</p>
			{actionText && onActionClick && (
				<button
					onClick={() => { onActionClick(); }}
					className="mt-6 rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
				>
					{actionText}
				</button>
			)}
		</div>
	);
};

export default EmptyComponent;
