import { makeAutoObservable, toJS } from "mobx";
import { User } from "app-ts-types/users";

class UserStore {
	user: User | null = JSON.parse(window.localStorage.getItem('user') || "null") || null;

	constructor() {
		makeAutoObservable(this);
	}

	setUser(user: User) {
		this.user = user;
	}

	getUser() {
		return toJS(this.user);
	}

	updateUser(updatedUser: Partial<User>) {
		if (this.user) {
			this.user = { ...this.user, ...updatedUser };
		}
	}

	updateUserField<K extends keyof User>(field: K, value: User[K]) {
		if (this.user) {
			this.user[field] = value;
		}
	}
}

export const userStore = new UserStore();
