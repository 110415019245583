import { makeAutoObservable } from "mobx";
import { Collection } from "app-ts-types/collections";

export class CollectionStore {
	collections: Collection[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "", direction: "" };
	currentPage: number = 1;
	itemsPerPage: number = 100;

	constructor() {
		makeAutoObservable(this);
	}

	setCollections(collections: Collection[]) {
		this.collections = collections;
	}

	addCollections(newItems: Collection[]) {
		this.collections = [...this.collections, ...newItems];
	}

	updateCollection(updatedItem: Collection) {
		const index = this.collections.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.collections[index] = updatedItem;
		}
	}

	deleteCollection(itemId: string) {
		this.collections = this.collections.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	get filteredAndSortedCollections(): Collection[] {
		let filteredCollections = this.collections;

		if (this.filter) {
			filteredCollections = filteredCollections.filter(item => item.id === this.filter);
		}

		if (this.sortOption.field) {
			filteredCollections = filteredCollections.sort((a, b) => {
				if (this.sortOption.direction === "ASCENDING") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}

		return filteredCollections;
	}

	get paginatedCollections(): Collection[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedCollections.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedCollections.length / this.itemsPerPage);
	}
}

export const collectionsStore = new CollectionStore();
