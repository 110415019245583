import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

interface IProps {platform:string; count:number;}

const DonutChart = ({ data, width, height, margin }: {data: IProps[], width: number, height: number, margin: number}) => {
	const chartRef: React.MutableRefObject<HTMLElement | null | undefined> = useRef();
	const [selectedArc, setSelectedArc] = useState(null);

	useEffect(() => {
		const radius = Math.min(width, height) / 2 - margin;

		// @ts-ignore
		d3.select(chartRef.current).selectAll("*").remove();

		// @ts-ignore
		const svg = d3.select(chartRef.current)
			.append("svg")
			.attr("width", width)
			.attr("height", height)
			.append("g")
			.attr("transform", `translate(${width / 2}, ${height / 2})`);

		// @ts-ignore
		const pie = d3.pie().value(d => d.count);
		const arc = d3.arc().innerRadius(radius * 0.6).outerRadius(radius);
		const arcHover = d3.arc().innerRadius(radius * 0.6).outerRadius(radius * 1.1);
		// Create a color scale
		const color = d3.scaleOrdinal()
			.domain(data.map(d => d.platform))
			.range(d3.schemeCategory10);

		// @ts-ignore
		svg.selectAll("path")
			// @ts-ignore
			.data(pie(data))
			.enter()
			.append("path")
			// @ts-ignore
			.attr("d", d => (selectedArc === d.data.platform ? arcHover(d) : arc(d)))
			// @ts-ignore
			.attr("fill", (d, i) => d.data.color || d3.schemeCategory10[i % 10]) // Use color from data or default
			// .attr("fill", d => color(d.data.platform))
			.attr("stroke", "currentColor")
			.style("stroke-width", "1px")
			.style("cursor", "pointer")
			// @ts-ignore
			.attr("class", d => (selectedArc === d.data.platform ? "selected" : ""))

			.on("mouseover", function (event, d) {
				// @ts-ignore
				if (selectedArc !== d.data.platform) {
					// @ts-ignore
					d3.select(this).transition().duration(200).attr("d", arcHover); // Expand arc on hover if not selected
				}
			})
			.on("mouseout", function (event, d) {
				// @ts-ignore
				if (selectedArc !== d.data.platform) {
					// @ts-ignore
					d3.select(this).transition().duration(200).attr("d", arc); // Return to original size if not selected
				}
			})
			.on("click", function (event, d) {
				// @ts-ignore
				if (selectedArc === d.data.platform) {
					setSelectedArc(null); // Deselect if clicked again
				} else {
					// @ts-ignore
					setSelectedArc(d.data.platform); // Select new arc
				}
			})
			.transition()
			.duration(300)

		// Add markers (either image or text) for each arc
		svg.selectAll("marker")
			// @ts-ignore
			.data(pie(data))
			.enter()
			.append("g")
			.each(function(d) {
				const markerGroup = d3.select(this);

				// @ts-ignore
				if (d.data.img) {
					// Add image if img is provided
					markerGroup.append("image")
						// @ts-ignore
						.attr("xlink:href", d.data.img)
						.attr("width", 30)
						.attr("height", 30)
						// @ts-ignore
						.attr("transform", `translate(${arc.centroid(d)})`)
						.attr("x", -15) // Center image horizontally
						.attr("y", -15); // Center image vertically
				} else {
					// Add text if img is null
					markerGroup.append("text")
						// @ts-ignore
						.text(d.data.platform)
						// @ts-ignore
						.attr("transform", `translate(${arc.centroid(d)})`)
						.attr("text-anchor", "middle")
						.style("font-size", "10px")
						.style("fill", "white"); // Adjust color as needed
				}
			});

	}, [data, selectedArc]);

	{/*@ts-ignore*/}
	return <div className={"dark:text-gray-900 text-white w-fit"} ref={chartRef}></div>;
};

export default DonutChart;
