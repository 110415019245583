'use client'

import React, {Fragment, useState} from 'react'
import {observer} from 'mobx-react-lite';
import {Menu, MenuButton, MenuItem, MenuItems, Transition} from '@headlessui/react'
import {
	EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'
import {Link} from "react-router-dom";
import {inventoryStore} from '../state/Inventory';
import {listingsStore} from '../state/Listing';
import {salesRecordsStore} from '../state/SalesRecord';
import {categoriesToJSON, Listing, MarketplacePlatform, marketplacePlatformToJSON} from "../utils/types";
import ListingImage from "../components/Image";
import {marketplaceImages} from "../components/ListingImageCloud";
import ListingItem from "../components/ListingItem";

const statuses: { [key: string]: any } = {
	Listed: 'text-green-700 bg-green-50 ring-green-600/20',
	Sold: 'text-green-700 bg-green-50 ring-green-600/20',
	Created: 'text-gray-600 bg-gray-50 ring-gray-500/10',
	Error: 'text-red-700 bg-red-50 ring-red-600/10',
}

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(' ')
}

const Homepage = observer(({
	                           handleCreateInventory,
	                           handleCreateCollection,
	                           handleCreateListing
                           }: {
	handleCreateInventory: () => void;
	handleCreateCollection: () => void;
	handleCreateListing: () => void;
}) => {
	const [loading, setLoading] = useState(false);

	const totalPurchaseHistory = inventoryStore.inventory.reduce((acc, item) => acc + item.sizes.reduce((ac, sz) => ac + (sz.quantity * item.purchaseDetails!.price), 0), 0).toFixed(2);
	const totalSales = salesRecordsStore.salesRecords.reduce((acc, sale) => acc + sale.price, 0).toFixed(2);
	let totalProfit:any = salesRecordsStore.salesRecords.reduce((acc, sale) => {
		const inventoryItem = inventoryStore.inventory.find(item => item.id === sale.productId);
		if (inventoryItem) {
			return acc + (sale.price - inventoryItem.purchaseDetails!!.price);
		}
		return acc;
	}, 0)
	if(totalProfit < 1) totalProfit = 0;
	totalProfit = totalProfit.toFixed(2);
	let totalLoss: any = salesRecordsStore.salesRecords.reduce((acc, sale) => {
		const inventoryItem = inventoryStore.inventory.find(item => item.id === sale.productId);
		if (inventoryItem && sale.price < inventoryItem.purchaseDetails!.price) {
			return acc + (sale.price - inventoryItem.purchaseDetails!.price);
		}
		return acc;
	}, 0);
	if(totalLoss > 0) totalLoss = 0;
	totalLoss = totalLoss.toFixed(2);

	const stats = [
		{
			name: 'Purchase History',
			value: `$${totalPurchaseHistory}`,
			change: `${inventoryStore.inventory.length} items`,
			changeType: 'positive'
		},
		{
			name: 'Sales',
			value: `$${totalSales}`,
			change: `${salesRecordsStore.salesRecords.length} sales`,
			changeType: 'positive'
		},
		{
			name: 'Total Profit', value: `$${totalProfit}`, change: `${salesRecordsStore.salesRecords.filter(sale => {
				const inventoryItem = inventoryStore.inventory.find(item => item.id === sale.productId);
				return inventoryItem && sale.price > inventoryItem.purchaseDetails!.price;
			}).length} items`, changeType: 'positive'
		},
		{
			name: 'Losses on sold items', value: `$${totalLoss}`, change: `${salesRecordsStore.salesRecords.filter(sale => {
				const inventoryItem = inventoryStore.inventory.find(item => item.id === sale.productId);
				return inventoryItem && sale.price < inventoryItem.purchaseDetails!.price;
			}).length} items`, changeType: 'negative'
		},
	];

	const topSoldItems = salesRecordsStore.salesRecords.reduce((acc: { [key: string]: any }, sale) => {
		if (!acc[sale.SKU]) {
			acc[sale.SKU] = {count: 0, total: 0, platforms: {}, imageUrl: "", title: ""};
		}
		acc[sale.SKU].count += 1;
		acc[sale.SKU].imageUrl = sale.imageUrl;
		acc[sale.SKU].title = sale.productTitle;
		acc[sale.SKU].total += sale.price;
		if (!acc[sale.SKU].platforms[sale.platform]) {
			acc[sale.SKU].platforms[sale.platform] = 0;
		}
		acc[sale.SKU].platforms[sale.platform] += 1;
		return acc;
	}, {});
	const sortedTopSoldItems = Object.entries(topSoldItems).sort((a, b) => b[1].count - a[1].count);
	const recentInventories = inventoryStore.inventory.slice(-50).reverse();
	const recentListings: Listing[] = listingsStore.listings.slice(-5).reverse();

	const [currentListing, setCurrentListing] = useState<Listing|null>(null);
	const [editListing, setEditListing] = useState<boolean>(false);

	const listingItemClickPopupOnClose = () => {
		setEditListing(false);
		setCurrentListing(null);
	}

	return (
		<>
			<main>
				{currentListing && <ListingItem del={undefined} edit={editListing} listing={currentListing} onClose={listingItemClickPopupOnClose} deleteClick={() => {}} editClick={() => {}}/>}
				<div className="relative isolate overflow-hidden">
					{/* Stats */}
					<div
						className="border-b border-b-gray-900/10 dark:border-b-gray-200/10 lg:border-t lg:border-t-gray-900/5 dark:lg:border-t-gray-200/10">
						<dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
							{stats.map((stat, statIdx) => (
								<Link
									key={stat.name}
									to={stat.name === "Purchase History" ? "/inventory" : `/sales?filter=${stat.name.toLowerCase()}`}
									className={classNames(
										statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
										'flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 dark:border-gray-200/10 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8',
									)}
								>
									<dt className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">{stat.name}</dt>
									<dd
										className={classNames(
											stat.changeType === 'negative' ? 'text-rose-600 dark:text-rose-600' : 'text-gray-700 dark:text-gray-500',
											'text-xs font-medium',
										)}
									>
										{stat.change}
									</dd>
									<dd
										className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-gray-300">
										{stat.value}
									</dd>
								</Link>
							))}
						</dl>
					</div>

					<div
						aria-hidden="true"
						className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
					>
						<div
							style={{
								clipPath:
									'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
							}}
							className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
						/>
					</div>
				</div>
				<div className="space-y-16 py-16 xl:space-y-20">
					<div
						className={"grid grid-cols-1 lg:grid-cols-2 lg:space-x-4 space-y-4 lg:space-y-0 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"}>
						<div className={"space-y-4 grid grid-rows-2"}>
							<div
								className="overflow-hidden border bg-white dark:bg-gray-800/10 dark:border-gray-700 sm:rounded-lg min-h-[30vh]">
								<div className="px-4 py-5 sm:p-6 h-full">
									<div className="flex items-center justify-between">
										<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Top Sold Items</h2>
										<Link to="/sales"
										      className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
											View all<span className="sr-only">, listings</span>
										</Link>
									</div>
									{sortedTopSoldItems.length > 0 ? (
										<ul className="mt-4 space-y-4 max-h-[27rem] overflow-y-scroll">
											{sortedTopSoldItems.map(([name, data]) => (
												<li key={name} className="border cursor-pointer dark:border-gray-700 items-center overflow-hidden bg-white dark:bg-gray-800 px-4 py-4 shadow sm:rounded-md sm:px-6 flex justify-between">
													<div className={"flex flex-shrink space-x-2"}>
														<ListingImage title={name} imageUrl={data.imageUrl}/>
														<div>
															<h3 className="text-sm font-medium text-gray-900 dark:text-white">{data.title}</h3>
															<p className="text-sm text-gray-500 dark:text-gray-400">{data.count} sold</p>
														</div>
													</div>
													<div className="text-sm text-gray-900 dark:text-white">${data.total.toFixed(2)}</div>
												</li>
											))}
										</ul>
									) : (
										<div className="flex items-center justify-center h-full">
											<p className="text-sm text-gray-500 dark:text-gray-400">No Items sold yet</p>
										</div>
									)}
								</div>
							</div>
							<div
								className="overflow-hidden border bg-white dark:bg-gray-800/10 dark:border-gray-700 sm:rounded-lg min-h-[30vh]">
								<div className="px-4 py-5 sm:p-6 h-full">
									<div className="flex items-center justify-between">
										<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Top
											Marketplaces</h2>
										<Link to="/marketplaces"
										      className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
											View all<span className="sr-only">, Marketplaces</span>
										</Link>
									</div>
									{Object.keys(topSoldItems).length > 0 ? (
										<ul className="mt-4 space-y-4 max-h-[27rem] overflow-y-scroll">
											{Object.entries(topSoldItems).map(([name, data]) => (
												<li key={name} className="border cursor-pointer dark:border-gray-700 items-center overflow-hidden bg-white dark:bg-gray-800 px-4 py-4 shadow sm:rounded-md sm:px-6 flex justify-between">
													<div>
														<h3 className="text-sm font-medium text-gray-900 dark:text-white">{name}</h3>
														<p className="text-sm text-gray-500 dark:text-gray-400">{data.count} sold</p>
													{/*	<span className="text-gray-800 dark:text-gray-200">*/}
							            {/*{marketplaceImages[updatedListing.platform]?.alt}*/}
							          {/*</span>*/}
														<p
															className="text-sm flex space-x-2 items-center text-gray-500 dark:text-gray-400"> Platforms: &nbsp; {Object.entries(data.platforms).map(([platform, count]) => <><img
															src={marketplaceImages[parseInt(platform)]?.src}
															alt={marketplaceImages[parseInt(platform)]?.alt}
															className="h-4 w-4 mr-1"
														/> {count} </>
														)}</p>
													</div>
													<div className="text-sm text-gray-900 dark:text-white">${data.total.toFixed(2)}</div>
												</li>
											))}
										</ul>
									) : (
										<div className="flex items-center justify-center h-full">
											<p className="text-sm text-gray-500 dark:text-gray-400">No information to display</p>
										</div>
									)}
								</div>
							</div>
						</div>
						<div
							className="overflow-hidden border min-h-[60vh] bg-white dark:bg-gray-800/10 dark:border-gray-700 sm:rounded-lg">
							<div className="px-4 py-5 sm:p-6 h-full">
								<div className="flex items-center justify-between">
									<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Recent
										Inventories</h2>
									<Link to="/inventory"
									      className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
										View all<span className="sr-only">, inventories</span>
									</Link>
								</div>
								{recentInventories.length > 0 ? (
									<ul className="mt-4 space-y-4 max-h-[55rem] overflow-y-scroll">
										{recentInventories.map((inventory) => (
											<li key={inventory.id} className="border cursor-pointer dark:border-gray-700 overflow-hidden bg-white dark:bg-gray-800 px-4 py-4 shadow sm:rounded-md sm:px-6 flex justify-between">
												<div className={"flex space-x-4"}>
													<ListingImage title={inventory.SKU} imageUrl={inventory.imageUrl}/>
													<div>
														<h3 className="text-sm font-medium text-gray-900 dark:text-white">{inventory.name}</h3>
														<p className="text-sm text-gray-500 dark:text-gray-400"> {inventory.SKU} &#x2022; {inventory.totalQuantity} items </p>
													</div>
												</div>
												<div className={"text-left min-w-32"}>
													<div
														className="text-sm text-gray-900 dark:text-white">
														${inventory.purchaseDetails!.price.toFixed(2)}
													</div>
													<p className="text-sm text-gray-500 dark:text-gray-400">Total: ${(inventory.totalQuantity * (inventory.purchaseDetails ? inventory.purchaseDetails?.price : 1)).toFixed(2)}</p>
												</div>
											</li>
										))}
									</ul>
								) : (
									<div className="flex items-center justify-center h-full">
										<p className="text-sm text-gray-500 text-center dark:text-gray-400">
											<span>No Inventories to display</span><br/>
											<span onClick={handleCreateInventory} className={"underline cursor-pointer text-brightPurple hover:text-lavender"}> Create new Inventory </span>
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
					{/* Recent client list*/}
					<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
						<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
							<div className="flex items-center justify-between">
								<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Recent Listings</h2>
								<Link to="/marketplaces"
								      className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
									View all<span className="sr-only">, listings</span>
								</Link>
							</div>
							{recentListings.length > 0 ? (
								<ul role="list"
								    className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
									{recentListings.map((listing) => (
										<li key={`${listing.id}-${listing.imageUrls.length}`}
										    className="overflow-hidden rounded-xl border border-gray-200 dark:border-gray-700">
											<div
												className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 dark:bg-gray-800 p-6">
												<ListingImage imageUrl={listing.imageUrls[0] || ""} title={listing.title} />
												<div
													className="text-sm font-medium leading-6 text-gray-900 dark:text-white">{listing.title}</div>
												<Menu as="div" className="relative ml-auto">
													<MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
														<span className="sr-only">Open options</span>
														<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
													</MenuButton>
													<Transition
														as={Fragment}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<MenuItems
															className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
															<MenuItem>
																{({active}) => (
																	<div
																		onClick={() => {setCurrentListing(listing);}}
																		className={classNames(
																			active ? 'bg-gray-100 dark:bg-gray-700' : '',
																			'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white'
																		)}
																	>
																		View<span className="sr-only">, {listing.title}</span>
																	</div>
																)}
															</MenuItem>
															<MenuItem>
																{({active}) => (
																	<div
																		onClick={() => {setEditListing(true); setCurrentListing(listing);}}
																		className={classNames(
																			active ? 'bg-gray-100 dark:bg-gray-700' : '',
																			'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white'
																		)}
																	>
																		Edit<span className="sr-only">, {listing.title}</span>
																	</div>
																)}
															</MenuItem>
														</MenuItems>
													</Transition>
												</Menu>
											</div>
											<dl className="-my-3 divide-y divide-gray-100 dark:divide-gray-800 px-6 py-4 text-sm leading-6">
												<div className="flex justify-between gap-x-4 py-3">
													<dt className="text-gray-500 dark:text-gray-400">Listing date</dt>
													<dd className="text-gray-700 dark:text-gray-300">
														<time
															dateTime={new Date(listing.createdAt).toISOString()}>{new Date(listing.createdAt).toLocaleDateString()}</time>
													</dd>
												</div>
												<div className="flex justify-between gap-x-4 py-3">
													<dt className="text-gray-500 dark:text-gray-400">Price</dt>
													<dd className="flex items-start gap-x-2">
														<div className="font-medium text-gray-900 dark:text-white">${listing.price.toFixed(2)}</div>
														<div
															className={classNames(
																statuses[listing.status],
																'rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
															)}
														>
															{listing.status}
														</div>
													</dd>
												</div>
											</dl>
										</li>
									))}
								</ul>
							) : (
								<p className="mt-4 text-sm text-gray-500 dark:text-gray-400">No recent listings to display</p>
							)}
						</div>
					</div>
				</div>
			</main>
		</>
	)
});

export default Homepage;
