import { useState } from 'react';

const Tooltip = ({ text, children }: { text: string; children: React.ReactNode }) => {
	const [isVisible, setIsVisible] = useState(false);

	return (
		<div className="relative flex items-center justify-center">
			<div
				onMouseEnter={() => setIsVisible(true)}
				onMouseLeave={() => setIsVisible(false)}
				className="cursor-pointer"
			>
				{children}
			</div>
			{isVisible && (
				<div className="absolute bottom-full px-3 py-0.5 text-sm text-white bg-black rounded shadow-lg">
					{text}
				</div>
			)}
		</div>
	);
};

export default Tooltip;
